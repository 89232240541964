import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App';

function importAll(r) {
    r.keys().forEach(r);
};

importAll(require.context('./libs/'));
importAll(require.context('./components/'));

ReactDOM.render(<App/>, document.getElementById('root'));
