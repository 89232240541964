window.TYP_SYSTEMU_DATA = [
    [Const.TYP_SYSTEMU_STARTER, 'Starter'],
    [Const.TYP_SYSTEMU_BASIC, 'Basic'],
    [Const.TYP_SYSTEMU_STANDARD, 'Standard'],
    [Const.TYP_SYSTEMU_OPTIMAL, 'Optimum'],
    [Const.TYP_SYSTEMU_PROFESSIONAL, 'Professional'],
    [null, '']
];

window.OPERATOR_ROLES_DATA = [
    [Const.OPERATOR_P_SERWIS, Lang.serwis],
    [Const.OPERATOR_P_ADMIN, Lang.administrator],
    [Const.OPERATOR_P_OPERATOR, Lang.operator],
    [Const.OPERATOR_P_RAPORT, Lang.raport],
    [Const.OPERATOR_P_DOSTAWCA, Lang.dostawca],
    [Const.OPERATOR_P_CLOUD, Lang.cloud]
];

window.TYP_POZYCJI_FBSH_DATA = [
    [Const.TYP_POZYCJI_FIRMA_BILL_STATE_HISTORY_KOSTKA, Lang.kostka],
    [Const.TYP_POZYCJI_FIRMA_BILL_STATE_HISTORY_CHANGE_TYP_SYSTEMU_DEVICE, Lang.zmiana_typu_sterownika]
];

window.TYP_URZADZENIA_DATA = [
    [Const.TYP_URZADZENIA_KDP, 'KDP'],
    [Const.TYP_URZADZENIA_SKPULITE, Lang.skpulite]
];

window.CUSTOM_MOD_FLAG_DATA = [
    ['default', Lang.domyslny],
    ['swimer', 'SWIMER']
];

window.MNGSELECTOR_DATA = [
    [null, Lang.domyslny],
    ['swimer', 'SWIMER'],
    ['nordic_energy_fuel', 'NORDIC ENERGY FUEL'],
    ['amax', 'AMAX'],
    ['arccan_serv', 'ARCCAN SERV'],
    ['flotlis', 'FLOTLIS'],
    ['fts', 'FTS'],
    ['nolen', 'NOLEN'],
    ['tgk', 'TGK'],
    ['tolen', 'TOLEN'],
    ['en_tank', 'EN-TANK']
];