Ext.define('ViewDashboard', {
    extend: 'Ext.panel.Panel',
    cls: Const.CUSTOMMODFLAG != 'external_sibuso' ? "a-ViewDashboard" : "",
    id: "dashboard",
    statics: {
        accessPropertyViewName: "access.view.cdashboard"
    },
    cat: "p",
    text: Lang.dashboard,
    buttonIconCls: "iconDashboard",
    layout: 'fit',
    frame: false,
    border: false,
    buttonId: "main-view-button-dashboard",
    items: [{
        region: "center",
        xtype: 'box',
        frame: false,
        border: false,
        layout: 'fit',
        cls: 'placeholder'
    }],
    initComponent: function () {
        this.callParent(arguments);
        performAjaxLPromise('rest/systemreq/getSignUser').then(data => {
            if (data.firma != null && data.firma.id != Const.FIRMA_ID1) {
                this.fetchFD();
            }
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        });
        this.bufferedTask = Ext.Function.createBuffered(() => {
            var mainpanel = this.down("#panelfirmadetails");
            if (mainpanel != null) {
                this.down("#panelfirmadetails").refreshData();
            }
        }, 500, this);
    },
    show: function () {
        this.callParent(arguments);
        this.bufferedTask();
    },
    fetchFD: function () {
        this.removeAll();
        this.add({xtype: "panelfirmadetails"});
        if (window.location.hash == '#uvroute=dashboard')
            Ext.getCmp(this.buttonId).handler();
    },
    resetDashboard: function () {
        this.removeAll();
        this.add({
            region: "center",
            xtype: 'box',
            frame: false,
            border: false,
            layout: 'fit',
            cls: "placeholder"
        });
    }
});
