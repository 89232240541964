Ext.define('WindowKostkiZasKonw', {
    extend: 'Ext.ux.window.WindowExd',
    cls: 'a-WindowKostkiZasKonw',
    title: "",
    width: 1200,
    height: 800,
    padding: 20,
    collapsible: false,
    firma: null,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    iskonversion: false,
    initComponent: function () {
        this.title = this.iskonversion ? Lang.konwersja : Lang.zasilenie;
        this.uiDtoState = {};
        var store = Ext.create('Ext.data.Store', {
            autoLoad: false,
            remoteSort: false,
            remoteFilter: false,
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: "uuid",
                fields: ["expireDate", "paid", "fbs", {
                    name: "uuid",
                    calculate: function (row) {
                        return row.fbs.uuid;
                    }
                }, {
                    name: "typKostki",
                    calculate: function (row) {
                        return row.fbs.kostkaname;
                    }
                }, {
                    name: "serialnumber",
                    calculate: function (row) {
                        return row.fbs.serialnumber;
                    }
                }, {
                    name: "additionalfeatures",
                    calculate: function (row) {
                        return row.fbs.additionalfeatures;
                    }
                }]
            }),
            proxy: {
                type: 'memory',
                reader: {
                    type: 'json'
                }
            },
            sorters: [{
                property: "expireDate",
                direction: "ASC"
            }]
        });
        this.deviceGrid = new Ext.ux.grid.GridPanelExd({
            title: Lang.urzadzenia,
            store: store,
            flex: 1,
            viewConfig: {
                forceFit: true,
                getRowClass: function (record, index) {
                    if (!record.data.paid) {
                        return 'rowRed';
                    } else {
                        return '';
                    }
                }
            },
            columns: [{
                header: Lang.numer_seryjny,
                dataIndex: "serialnumber",
                type: 'auto',
                flex: 1
            }, {
                header: Lang.data_wygasniecia,
                dataIndex: "expireDate",
                type: 'auto',
                flex: 1
            }, {
                header: Lang.typ_kostki,
                dataIndex: "typKostki",
                type: 'auto',
                flex: 1
            }, {
                header: Lang.opcje,
                flex: 1,
                dataIndex: 'additionalfeatures'
            }],
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        this.items = [{
            xtype: "container",
            layout: {
                type: "vbox",
                align: "stretch"
            },
            items: [{
                xtype: "panel",
                layout: {
                    type: "vbox",
                    align: "stretch"
                },
                itemId: 'warning_panel',
                flex: 0.2,
                items: [{
                    xtype: 'displayfield',
                    itemId: 'warning_message',
                    value: Lang.istnieje_niezatwierdzony_rachunek,
                    fieldStyle: 'color: red; text-align: center;'
                }]
            }, {
                xtype: "panel",
                title: Lang.kostki,
                layout: {
                    type: "vbox",
                    align: "stretch"
                },
                flex: 1,
                scrollable: true,
                itemId: "kostki_panel",
                padding: "5 5 25 5",
                items: [],
                buttons: [{
                    xtype: 'tbfill'
                }, {
                    itemId: 'calculate',
                    text: Lang.przelicz,
                    scope: this,
                    handler: this.calculateOp
                }]
            }, {
                xtype: "container",
                layout: {
                    type: "vbox",
                    align: "stretch"
                },
                flex: 1,
                items: [this.deviceGrid]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            itemId: 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            itemId: 'saveExt',
            text: Lang.zatwierdz,
            scope: this,
            handler: this.saveOpExt
        }, {
            itemId: 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.close
        }];
        this.callParent(arguments);
        this.fillUp();
    },
    fillUp: function () {
        sendAjaxDataJsonRpcL("rest/billingHistory/isUnapprovedExists", null, this, function (data) {
            if (data.status) {
                if (data.data) {
                    this.down("#warning_panel").setHidden(false);
                    this.down("#save").setHidden(true);
                    this.down("#saveExt").setHidden(true);
                } else {
                    this.down("#warning_panel").setHidden(true);
                    this.down("#save").setHidden(false);
                    this.down("#saveExt").setHidden(false);
                }
            }
        });
        sendAjaxDataJsonRpcL("rest/magazyn/get", null, this, function (data) {
            if (data.status) {
                data = data.data;
                this.deviceGrid.store.loadData(data.magazynDevices);
                this.uiDtoState = data;
                this.buildKostkiPanel(data.magazynKostki);
                this.fillUpKostkiPanel(data.magazynKostki);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    showW: function () {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.close
            }
        });
    },
    buildKostkiPanel: function (kostki) {
        let kpanel = this.down("#kostki_panel");
        let kitems = [{
            xtype: "container",
            layout: {
                type: "hbox",
                align: "stretch"
            },
            margin: "0 0 5 0",
            items: [{
                xtype: "displayexd",
                style: "padding-right:5px",
                fieldLabel: Lang.typ_kostki,
                flex: 1
            }, {
                xtype: "displayexd",
                style: "padding-right:5px",
                fieldLabel: Lang.ilosc_poczatkowa,
                flex: 1
            }, {
                xtype: "displayexd",
                style: "padding-right:5px",
                fieldLabel: this.iskonversion ? Lang.konwersja : Lang.zasilenie,
                flex: 1
            }, {
                xtype: "displayexd",
                style: "padding-right:5px",
                fieldLabel: this.iskonversion ? Lang.stan_po_konwersji : Lang.stan_po_zasileniu,
                flex: 1
            }, {
                xtype: "displayexd",
                fieldLabel: Lang.typ_rozliczenia,
                flex: 1
            }]
        }];
        for (let i = 0; i < kostki.length; i++) {
            let typKostki = kostki[i].kostkaDto.typkostki;
            kitems.push({
                xtype: "container",
                layout: {
                    type: "hbox",
                    align: "stretch"
                },
                items: [{
                    xtype: "textfield",
                    itemId: `${typKostki}_tk`,
                    style: "padding-right:5px",
                    flex: 1,
                    editable: false
                }, {
                    xtype: "numberfield",
                    itemId: `${typKostki}_ip`,
                    style: "padding-right:5px",
                    flex: 1,
                    editable: false
                }, {
                    xtype: "numberfield",
                    itemId: `${typKostki}_zm`,
                    style: "padding-right:5px",
                    editable: !this.iskonversion,
                    flex: 1,
                    minValue: this.iskonversion ? false : 0,
                    listeners: {
                        change: function () {
                            this.down("#calculate").setDisabled(false);
                            this.down("#save").setDisabled(true);
                            this.down("#saveExt").setDisabled(true);
                        },
                        scope: this
                    }
                }, {
                    xtype: "numberfield",
                    itemId: `${typKostki}_ik`,
                    style: "padding-right:5px",
                    editable: this.iskonversion,
                    flex: 1,
                    minValue: 0,
                    listeners: {
                        change: function () {
                            this.down("#calculate").setDisabled(false);
                            this.down("#save").setDisabled(true);
                            this.down("#saveExt").setDisabled(true);
                        },
                        scope: this
                    }
                }, {
                    xtype: "textfield",
                    itemId: `${typKostki}_tr`,
                    flex: 1,
                    editable: false
                }]
            });
        }
        kpanel.add(kitems);
    },
    fillUpKostkiPanel: function (kostki) {
        for (let i = 0; i < kostki.length; i++) {
            var currentKostka = kostki[i];
            let typKostki = currentKostka.kostkaDto.typkostki;
            var allowed = currentKostka.kostkaDto.allowed

            var kostkaNazwaField = this.down('#' + typKostki + '_tk');
            kostkaNazwaField.setValue(currentKostka.kostkaDto.kostkaname);
            kostkaNazwaField.setDisabled(!allowed || !kostkaNazwaField.getEditable());

            var kostkaIloscPoczField = this.down('#' + typKostki + '_ip');
            kostkaIloscPoczField.setValue(currentKostka.kostkaDto.ilosckostek);
            kostkaIloscPoczField.setDisabled(!allowed || !kostkaIloscPoczField.getEditable());

            var kostkaZmianaField = this.down('#' + typKostki + '_zm');
            kostkaZmianaField.setValue(currentKostka.zmiana);
            kostkaZmianaField.setDisabled(!allowed || !kostkaZmianaField.getEditable());

            var kostkaIloscKoncField = this.down('#' + typKostki + '_ik');
            kostkaIloscKoncField.setValue(currentKostka.kostkaDto.ilosckostek + currentKostka.zmiana);
            kostkaIloscKoncField.setDisabled(!allowed || !kostkaIloscKoncField.getEditable());

            var kostkaTypRozlField = this.down('#' + typKostki + '_tr');
            kostkaTypRozlField.setValue(currentKostka.magazynTypRozliczeniaRepresentation);
            kostkaTypRozlField.setDisabled(!allowed || !kostkaTypRozlField.getEditable());
        }
        this.down("#calculate").setDisabled(true);
    },
    saveOp: function () {
        if (this.refreshAndValidateData()) {
            sendAjaxDataJsonRpcL("rest/magazyn/save", this.uiDtoState, this, function (data) {
                if (data.status) {
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        }
    },
    saveOpExt: function () {
        if (this.refreshAndValidateData()) {
            sendAjaxDataJsonRpcL("rest/magazyn/save", this.uiDtoState, this, function (data) {
                if (data.status) {
                    if (data.data != null) {
                        windowObject = Ext.create('WindowOrderDetails', {numerrachunku: data.data})
                        windowObject.showW();
                        windowObject.on('closeSuccess', this.closeExt, this);
                        this.hide();
                    } else {
                        this.close();
                    }
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        }
    },
    refreshAndValidateData: function () {
        var changeBalance = 0;
        for (let i = 0; i < this.uiDtoState.magazynKostki.length; i++) {
            let typKostki = this.uiDtoState.magazynKostki[i].kostkaDto.typkostki;
            var formZmiana = this.down('#' + typKostki + '_zm').getValue();
            var formIloscPocz = this.down('#' + typKostki + '_ip').getValue();
            var formIloscKonc = this.down('#' + typKostki + '_ik').getValue();
            var transferZmiana;
            if (formZmiana == null) {
                Ext.MessageBox.alert(Lang.error, Lang.pole_zasilenie_konwersja_nie_moze_byc_puste);
                return false;
            }
            if (this.iskonversion) {
                transferZmiana = formIloscKonc - formIloscPocz;
            } else {
                transferZmiana = formZmiana;
            }
            if ((formIloscPocz + transferZmiana) < 0 || formIloscKonc < 0) {
                Ext.MessageBox.alert(Lang.error, Lang.stan_docelowy_po_zmianie_ma_byc_wiekszy_od_0);
                return false;
            }
            this.uiDtoState.magazynKostki[i].zmiana = transferZmiana;
            changeBalance += transferZmiana;
        }
        if (changeBalance < 0) {
            Ext.MessageBox.alert(Lang.error, Lang.bilans_zmian_ma_byc_wiekszy_od_0);
            return false;
        }
        return true;
    },
    calculateOp: function () {
        if (this.refreshAndValidateData()) {
            sendAjaxDataJsonRpcL("rest/magazyn/update", this.uiDtoState, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.uiDtoState = data;
                    this.deviceGrid.store.loadData(data.magazynDevices);
                    this.fillUpKostkiPanel(data.magazynKostki);
                    this.down("#save").setDisabled(false);
                    this.down("#saveExt").setDisabled(false);
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                    this.fillUp();
                }
            });
        }
    },
    close: function () {
        this.callParent(arguments);
    },
    closeExt: function () {
        this.fireEvent('closeSuccess');
        this.close();
    }
});