Ext.define('WindowManageManagingCompanies', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.firmy_zarzadzajace.capitalise(),
    width: 1000,
    height: 600,
    collapsible: false,
    modal: true,
    resizable: false,
    taskContextMenu: null,
    scrollable: false,
    closable: true,
    rowContext: null,
    layout: 'border',
    dashboard: false,
    statics: {
        accessPropertyManageName: "access.manage.cfirmyzarzadzajace",
        accessPropertyViewName: "access.view.cfirmyzarzadzajace"
    },
    initComponent: function () {
        this.treepanel = Ext.create('Ext.ux.tree.PanelExd', {
            rootVisible: false,
            titleCollapse: false,
            animate: false,
            hideHeaders: true,
            style: 'padding: 10px',
            store: createTreeStore()
        });
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            scrollable: true,
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(WindowManageManagingCompanies.accessPropertyManageName)
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                cls: 'x-btn-mobile',
                disabled: !validPrivilegesOperatorComplex(WindowManageManagingCompanies.accessPropertyManageName)
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                text: Lang.usun.capitalise(),
                scope: this,
                handler: this.usunObiekt,
                cls: 'x-btn-mobile',
                disabled: !validPrivilegesOperatorComplex(WindowManageManagingCompanies.accessPropertyManageName)
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }]
        }, {
            xtype: 'panel',
            region: 'center',
            scrollable: 'y',
            items: [this.treepanel]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            text: Lang.zamknij,
            scope: this,
            handler: this.closeOp
        }];
        this.treepanel.addListener('rowdblclick', function () {
            if (validPrivilegesOperatorComplex(WindowManageManagingCompanies.accessPropertyManageName)) {
                this.edytujObiekt();
            }
            return false
        }, this);
        this.treepanel.addListener('select', function () {
            this.manageSelect();
        }, this);
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        this.refreshDatastore();
    },
    saveOp: function () {
    },
    closeOp: function () {
        this.close();
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        var bdelete = Ext.getCmp(this.id + "bdelete");
        var bedit2 = Ext.getCmp(this.id + "bedit2");
        var bdelete2 = Ext.getCmp(this.id + "bdelete2");
        var access = validPrivilegesOperatorComplex(WindowManageManagingCompanies.accessPropertyManageName);
        if (!this.treepanel.getSelection() || this.treepanel.getSelection() == null || this.treepanel.getSelection().length == 0) {
            bdelete?.setDisabled(true);
            bedit?.setDisabled(true);
        } else {
            bdelete?.setDisabled(!access);
            bedit?.setDisabled(!access);
        }
        if (this.rowContext == null) {
            bdelete2?.setDisabled(true);
            bedit2?.setDisabled(true);
        } else {
            bdelete2?.setDisabled(!access);
            bedit2?.setDisabled(!access);
        }
    },
    refreshDatastore: function (cfg) {
        this.mask(Lang.ladowanie);
        performAjaxLPromise('rest/firmyzarzadzajace/getList').then(data => {
            const children = generateFirmaTreePanelChildren(data);
            this.treepanel.setStore(createTreeStore(children));
            if (cfg && cfg.expandPathId) {
                this.treepanel.expandPath(`${cfg.expandPathId}`);
            }
            this.manageSelect();
            this.center();
        }).catch(err => {
            handleCommonCallBackAjaxRPCError(err);
        }).finally(() => {
            this.unmask();
        });
    },
    dodajObiekt: function () {
        let windowObject = Ext.create('WindowManagingCompany');
        windowObject.on('closeSuccess', this.refreshDatastore, this);
        windowObject.showW(0);
    },
    edytujObiekt: function () {
        let id = 0;
        if (!this.treepanel.getSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.treepanel.getSelection() != null && this.treepanel.getSelection().length == 1) {
                id = this.treepanel.getSelection()[0].id;
            }
        }
        let windowObject = Ext.create('WindowManagingCompany');
        windowObject.on('closeSuccess', this.refreshDatastore, this);
        windowObject.showW(id);
    },
    usunObiekt: function () {
        let id = 0;
        if (!this.treepanel.getSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.treepanel.getSelection() != null && this.treepanel.getSelection().length == 1) {
                id = this.treepanel.getSelection()[0].id;
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            msg: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    this.mask(Lang.ladowanie);
                    performAjaxLPromise('rest/firmyzarzadzajace/deleteObject', {
                        id
                    }).then(() => {
                        this.unmask();
                        this.refreshDatastore();
                    }).catch(err => {
                        this.unmask();
                        handleCommonCallBackAjaxRPCError(err);
                    });
                }
            }
        });
    }
});
