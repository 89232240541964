Ext.define('WindowKreatorZmienTypSystemu', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.zmien_typ_systemu,
    width: 590,
    padding: 10,
    collapsible: false,
    firma: null,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    currentPage: 0,
    initComponent: function () {
        this.items = [{
            itemId: 'kreator-panel',
            xtype: 'panel',
            layout: 'card',
            bbar: ['->', {
                xtype: 'button',
                itemId: 'wroc',
                text: Lang.wroc,
                disabled: true,
                scope: this,
                handler: this.performPrevStep
            }, {
                xtype: 'button',
                itemId: 'dalej',
                text: Lang.dalej,
                scope: this,
                handler: this.performNextStep
            }
            ],
            items: [{
                itemId: 'card-0',
                xtype: 'container',
                layout: 'vbox',
                defaults: {
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    itemId: "currentTypSystemu",
                    width: '100%',
                    name: "currentTypSystemu",
                    xtype: 'textfieldexd',
                    labelWidth: 150,
                    fieldLabel: Lang.aktualny_typ_systemu,
                    resizable: false,
                    editable: false,
                    allowBlank: false
                }]
            }, {
                xtype: 'container',
                layout: 'vbox',
                itemId: 'card-1'
            }]
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.close
            }
        });
        this.mask(Lang.ladowanie);
        performAjaxLPromise('rest/dashboard/getFullInfo').then(data => {
            this.firma = data.firma;
            let typSystemuTrial = this.firma.billingInfo.typSystemuTrial;
            let typSystemu = this.firma.billingInfo.typSystemu;
            let customModFlag = this.firma.custommodflag;
            let typSystemuStore = new Ext.data.ArrayStore({
                data: TYP_SYSTEMU_DATA,
                fields: ['value', 'text']
            });
            let typSystemuValue;
            let firstCardItems;
            if (typSystemuTrial != null) {
                typSystemuValue = `${TYP_SYSTEMU_DATA.find(type =>
                    type[0] == typSystemu)[1]} / ${TYP_SYSTEMU_DATA.find(type => type[0] == typSystemuTrial)[1]} (trial)`;
                firstCardItems = [{
                    html: Lang.zatwierdz_upgrade,
                    padding: 10
                }, {
                    xtype: 'fieldset',
                    width: '100%',
                    items: [{
                        itemId: 'acceptTrial',
                        name: 'acceptTrial',
                        xtype: 'radio',
                        boxLabel: Lang.zatwierdz,
                        inputValue: true,
                        value: false,
                        listeners: {
                            scope: this,
                            change: () => {
                                this.down('#revokeTrial').clearInvalid();
                            }
                        }
                    }, {
                        itemId: 'revokeTrial',
                        name: 'acceptTrial',
                        xtype: 'radio',
                        boxLabel: Lang.odrzuc,
                        inputValue: true,
                        value: false,
                        listeners: {
                            scope: this,
                            change: () => {
                                this.down('#acceptTrial').clearInvalid();
                            }
                        }
                    }]
                }];
            } else {
                typSystemuValue = TYP_SYSTEMU_DATA.find(type => type[0] == typSystemu)[1];
                firstCardItems = [{
                    xtype: 'container',
                    layout: WindowExdMobileDEVICEV ? 'vbox' : 'hbox',
                    items: [{
                        itemId: "typSystemu",
                        name: "typSystemu",
                        xtype: 'comboexd',
                        labelWidth: 150,
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left',
                        store: typSystemuStore,
                        fieldLabel: Lang.nowy_typ_systemu,
                        displayField: 'text',
                        valueField: 'value',
                        value: typSystemu,
                        triggerAction: 'all',
                        resizable: false,
                        mode: 'local',
                        editable: false,
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        itemId: 'wantsTrial',
                        xtype: 'checkboxfield',
                        labelWidth: 150,
                        margin: WindowExdMobileDEVICEV ? '' : '0 0 0 20',
                        value: false,
                        fieldLabel: Lang.uruchom_trial,
                        defaultType: 'checkbox',
                        items: [{
                            inputValue: true
                        }]
                    }]
                }, {
                    xtype: 'container',
                    margin: '10 0 0 0',
                    defaults: {
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        itemId: 'customModFlag',
                        name: 'customModFlag',
                        xtype: 'comboexd',
                        labelWidth: 150,
                        store: CUSTOM_MOD_FLAG_DATA,
                        fieldLabel: Lang.modyfikacja_licencji,
                        displayField: 'text',
                        valueField: 'text',
                        triggerAction: 'all',
                        resizable: false,
                        mode: 'local',
                        editable: false,
                        allowBlank: false,
                        hidden: AppOPERATORMNGFIRMAID != Const.FIRMA_ZARZADZAJACA_ID1,
                        value: AppOPERATORMNGFIRMAID == Const.FIRMA_ZARZADZAJACA_ID1 ? null : 'default',
                        blankText: Lang.pole_nie_moze_byc_puste
                    }]
                }];
            }
            this.down('#currentTypSystemu').setValue(typSystemuValue);
            this.down('#card-0').add(firstCardItems);
            if (this.down('#customModFlag'))
                this.down('#customModFlag').setValue(customModFlag == null ? 'default' : customModFlag);
            this.unmask();
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
            this.close()
        });
    },
    performPrevStep: function () {
        let wroc = this.down('#wroc');
        let dalej = this.down('#dalej');
        dalej.setDisabled(false);
        wroc.setDisabled(true);
        dalej.setText(Lang.dalej);
        dalej.setHandler(this.performNextStep);
        this.currentPage -= 1;
        this.down('#kreator-panel').setActiveItem(this.currentPage);
    },
    performNextStep: function () {
        let card1 = this.down('#card-1');
        let wroc = this.down('#wroc');
        let dalej = this.down('#dalej');
        let typSystemuTrial = this.firma.billingInfo.typSystemuTrial;
        if (this.currentPage == 0) {
            if (typSystemuTrial != null) {
                let acceptTrial = this.down('#acceptTrial');
                let revokeTrial = this.down('#revokeTrial');
                let acceptTrialValue = acceptTrial.getValue();
                let revokeTrialValue = revokeTrial.getValue();
                if (!acceptTrialValue && !revokeTrialValue) {
                    acceptTrial.markInvalid(Lang.wybierz_opcje);
                    revokeTrial.markInvalid(Lang.wybierz_opcje);
                    return
                }
                card1.removeAll();
                let areYouSure;
                acceptTrialValue ?
                    areYouSure = `<h3>${Lang.czy_zatwierdzic_upgrade}</h3>`
                    :
                    areYouSure = `<h3>${Lang.czy_odrzucic_upgrade}</h3>`;
                card1.add({
                    html: areYouSure,
                    maxWidth: Ext.getBody().getViewSize().width * .96
                });
                dalej.setText(Lang.tak);
            } else {
                let newTypSystemu = this.down('#typSystemu');
                let wantsTrial = this.down('#wantsTrial').getValue();
                if (newTypSystemu.getValue() == null) {
                    newTypSystemu.markInvalid(Lang.dane_niepoprawne);
                    return
                }
                let newSystemName;
                if (wantsTrial) {
                    newSystemName = `<h3>${TYP_SYSTEMU_DATA.find(type => type[0] == newTypSystemu.getValue())[1]} (trial)</h3>`;
                } else {
                    newSystemName = `<h3>${TYP_SYSTEMU_DATA.find(type => type[0] == newTypSystemu.getValue())[1]}</h3>`;
                }
                card1.removeAll();
                card1.add([{
                    html: Lang.twoj_nowy_typ_systemu
                }, {
                    html: newSystemName
                }]);
                dalej.setText(Lang.zmien);
            }
            wroc.setDisabled(false);
            dalej.setHandler(this.zmienTypSystemu);
        }
        this.currentPage += 1;
        this.down('#kreator-panel').setActiveItem(this.currentPage);

    },
    zmienTypSystemu: function () {
        let wroc = this.down('#wroc');
        let dalej = this.down('#dalej');
        let newSystem;
        let typSystemuTrial = this.firma.billingInfo.typSystemuTrial;
        if (typSystemuTrial != null) {
            newSystem = {
                acceptTrial: this.down('#acceptTrial').getValue(),
                revokeTrial: this.down('#revokeTrial').getValue()
            }
        } else {
            newSystem = {
                typSystemu: this.down('#typSystemu').getValue(),
                trial: this.down('#wantsTrial').getValue(),
                custommodflag: this.down('#customModFlag').getValue()
            }
        }
        this.mask(Lang.ladowanie);
        performAjaxLPromise('rest/billing/setSystemTypeForCurrentFirm', newSystem).then(() => {
            this.down('#kreator-panel').add({
                xtype: 'container',
                layout: 'center',
                items: [{
                    html: `<h3>${Lang.dziekujemy}</h3>`
                }]
            });
            wroc.destroy();
            dalej.setText(Lang.zamknij);
            dalej.setHandler(this.close);
            this.currentPage += 1;
            this.down('#kreator-panel').setActiveItem(this.currentPage);

        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        }).finally(() => {
            this.unmask();
        });
    },
    close: function () {
        this.fireEvent('closeSuccess');
        this.callParent(arguments);
    }
});