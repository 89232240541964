window.renderBool2 = (val) => {
    return '' + '<div style="text-align:center;height: 18px;width: 18px;overflow:visible;display: block;margin-right: auto;margin-left: auto;"' + (val ? ' class="x-form-cb-checked"' : "") + '> <div class="x-form-checkbox-default"/>' + '</div>';
};

window.renderBool3 = (val) => {
    return '' + '<div class="iconDivColValueRenderBool' + (val ? " x-form-cb-checked" : "") + '"> <div class="x-form-checkbox-default"/>' + '</div>';
};

window.renderBool4 = (val) => {
    return '' + '<span class="iconDivColValueRenderBool mobile' + (val ? " x-form-cb-checked" : "") + '"> <span class="x-form-checkbox-default"/>' + '</span>';
};

window.renderBoolIsSerwisant = (val) => {
    var res = val == 0 ? true : false;
    return '' + '<div class="iconDivColValueRenderBool' + (res ? " x-form-cb-checked" : "") + '"> <div class="x-form-checkbox-default"/>' + '</div>';
};

window.isValidOperatorFirmaLimit = () => {
    return (AppOPERATORFIRMA != null)
}

window.isValidOperatorFirmaRaw = () => {
    return (AppOPERATORFIRMA != null)
}

window.validPrivilegesOperatorComplex = (accessNameRecordOpFull) => {
    return AppOPERATOR.privileges[accessNameRecordOpFull] == null ? false : AppOPERATOR.privileges[accessNameRecordOpFull];
}

window.notEmptyAfterTrim = function (v) {
    return v && v.length && v.trim && v.trim().length > 0
};

window.createTreeStore = (children = []) => {
    return Ext.create('Ext.data.TreeStore', {
        root: {
            text: 'Root',
            expanded: true,
            children
        },
        folderSort: true,
        sorters: [{
            property: 'text',
            direction: 'ASC'
        }]
    });
};

window.generateFirmaTreePanelChildren = function (parent, includeRoot = true) {
    if (parent.childList.length == 0) {
        return includeRoot ? [{
            ...parent,
            text: parent.nazwa,
            expanded: true,
            iconCls: 'fa-building'
        }] : []
    } else {
        const mappedChildList = parent.childList.map(child => ({
            id: child.id,
            idparent: child.idparent,
            email: child.email,
            text: child.nazwa,
            opis: child.opis,
            children: generateFirmaTreePanelChildren(child, false),
            iconCls: 'fa-building'
        }));
        return includeRoot ? [{
            ...parent,
            text: parent.nazwa,
            expanded: true,
            iconCls: 'fa-building',
            children: mappedChildList
        }] : mappedChildList;
    }
}

window.checkIfHttpsRenderCzytnikFixBtn = () => {
    if (window.location.protocol == 'https:') {
        return `${Lang.czytnik_brak_polaczenia_z_usluga}<button
            onclick="Ext.create('WindowCzytnikCertPanel').showW();">${Lang.rozwiazywanie_problemow}</button>`
    } else {
        return Lang.czytnik_brak_polaczenia_z_usluga
    }
};

window.resolveHtmlDocumentLang = function () {
    if (Const.CURRENTLANG == "pl") {
        return "_pl"
    } else return "_en"
};
