Ext.define('ViewRachunki', {
    extend: 'Ext.panel.Panel',
    grid: null,
    windowObject: null,
    id: "rachunki",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyManageName: "access.manage.crachunki",
        accessPropertyViewName: "access.view.crachunki"
    },
    cat: "p",
    text: Lang.rachunki,
    buttonIconCls: "iconRachunek",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                iconCls: 'iconEdit',
                xtype: 'button',
                text: Lang.podglad,
                scope: this,
                handler: this.otworzRachunek
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    buildGrid: function () {
        var cols = [{
            header: Lang.numer_rachunku,
            flex: 1,
            sortable: true,
            filterable: true,
            dataIndex: 'numerrachunku',
            filter: {
                type: 'numeric'
            }
        }, {
            header: Lang.firma,
            flex: 2,
            sortable: true,
            filterable: true,
            dataIndex: 'firma',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.data_utworzenia,
            flex: 2,
            sortable: true,
            filterable: true,
            dataIndex: 'datautworzenia',
            filter: {
                type: 'date'
            }
        }, {
            header: Lang.oplacono,
            flex: 1,
            sortable: true,
            filterable: true,
            dataIndex: 'flagaoplacenia',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            }
        }, {
            header: Lang.zatwierdzono,
            flex: 1,
            sortable: true,
            filterable: true,
            dataIndex: 'flagazatwierdzenia',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            }
        }, {
            header: Lang.faktura_zalaczona,
            flex: 2,
            sortable: true,
            dataIndex: 'flagazalaczeniafaktury',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.faktura_wyslana,
            flex: 2,
            sortable: true,
            dataIndex: 'flagawyslaniafaktura',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.proforma_zalaczona,
            flex: 2,
            sortable: true,
            dataIndex: 'flagazalaczeniaproforma',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.proforma_wyslana,
            flex: 2,
            sortable: true,
            dataIndex: 'flagawyslaniaproforma',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.data_dodania_faktury,
            flex: 2,
            sortable: true,
            dataIndex: 'fakturadatadodania',
            filter: {
                type: 'date'
            }
        }, {
            header: Lang.termin_platnosci,
            flex: 1,
            sortable: true,
            dataIndex: 'fakturaterminplatnosci',
            filter: {
                type: 'numeric'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.rozliczono_arccan,
            flex: 1,
            sortable: true,
            dataIndex: 'rozliczono_arccan',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: AppOPERATORFIRMA != null
        }, {
            header: Lang.zamowienie_kontr,
            flex: 1,
            sortable: true,
            dataIndex: 'zamowienie_kontr',
            filter: {
                type: 'string'
            },
            hidden: AppOPERATORFIRMA != null
        }, {
            header: Lang.operator,
            flex: 2,
            sortable: true,
            dataIndex: 'userimienazwisko',
            filter: {
                type: 'string'
            },
            filterable: true
        }];
        let store = new Ext.data.Store({
            remoteSort: true,
            remoteFilter: true,
            autoLoad: false,
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: "numerrachunku",
                fields: [{
                    name: 'numerrachunku',
                    mapping: 'numerrachunku'
                }, {
                    name: 'firma',
                    mapping: 'firma'
                }, {
                    name: 'datarozpoczecia',
                    mapping: 'datarozpoczecia'
                }, {
                    name: 'datazakonczenia',
                    mapping: 'datazakonczenia'
                }, {
                    name: 'flagaoplacenia',
                    mapping: 'flagaoplacenia'
                }, {
                    name: 'flagazatwierdzenia',
                    mapping: 'flagazatwierdzenia'
                }, {
                    name: 'flagauniewznienia',
                    mapping: 'flagauniewznienia'
                }, {
                    name: 'flagazalaczeniafaktury',
                    mapping: 'flagazalaczeniafaktury'
                }, {
                    name: 'flagawyslaniafaktura',
                    mapping: 'flagawyslaniafaktura'
                }, {
                    name: 'flagazalaczeniaproforma',
                    mapping: 'flagazalaczeniaproforma'
                }, {
                    name: 'flagawyslaniaproforma',
                    mapping: 'flagawyslaniaproforma'
                }, {
                    name: 'fakturadatadodania',
                    mapping: 'fakturadatadodania'
                }, {
                    name: 'fakturaterminplatnosci',
                    mapping: 'fakturaterminplatnosci'
                }, {
                    name: 'zamowienie_kontr',
                    mapping: 'zamowienie_kontr'
                }, {
                    name: 'rozliczono_arccan',
                    mapping: 'rozliczono_arccan'
                }, {
                    name: 'userimienazwisko',
                    mapping: 'userimienazwisko'
                }]
            }),
            sorters: [{
                property: "numerrachunku",
                direction: "DESC"
            }],
            proxy: {
                type: 'ajax',
                url: 'rest/billingHistory/getListForAllAvailableFirms',
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'numerrachunku'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true
            },
            store: store,
            columns: cols,
            frame: false,
            border: false,
            stateId: "grid-" + this.id + "-all",
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagingtoolbarexd',
                displayInfo: true,
                pagingStore: store
            }
        });
        this.grid.addListener("rowdblclick", function () {
            this.otworzRachunek();
        }, this);
    },
    refreshDatastore: function () {
        this.grid.store.load();
    },
    otworzRachunek: function () {
        let rachunekId = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                rachunekId = this.rowContext.numerrachunku;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                rachunekId = this.grid.getSelectionModel().getSelection()[0].data.numerrachunku;
            }
        }
        if (rachunekId != 0) {
            windowObject = Ext.create('WindowOrderDetails', {numerrachunku: rachunekId})
            windowObject.showW();
            windowObject.on('closeSuccess', this.refreshDatastore, this);
        }
    },
    afterRender: function () {
        this.refreshDatastore();
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    }
});