export default function Login() {
    window._HandleStandardExceptionAlert = Const.DEBUG;
    window._HandleStandardExceptionReloadPage = false; //!Const.DEBUG;
    window._HandleStandardExceptionAlertOnError = Const.DEBUG;
    window._HandleStandardExceptionReloadPageOnError = false; //!Const.DEBUG;

    var enterHandler = () => submitbtn();

    var ExtonReadyMainExecutedFirst = false;

    Ext.onReady(function () {
        if (ExtonReadyMainExecutedFirst) {
            return;
        }
        ExtonReadyMainExecutedFirst = true;
        Ext.tip.QuickTipManager.init();
        var loginpagep0 = Ext.get("loginpage-panel");
        var loginpagep = Ext.get("loginpage-panels");
        var loginpagepc = Ext.get("panel-login-container");
        let handleLoginPageResize = function () {
            var panelFormContainer = Ext.getCmp('login-panel-form-container');
            var wheight;
            var wwidth;
            if (Ext.os.is.Phone) {
                wheight = Ext.getBody().getViewSize().height;
                wwidth = Ext.getBody().getViewSize().width;
                loginpagepc.setWidth(wwidth);
                loginpagepc.setHeight(wheight);
                loginpagep0.center();
                loginpagep.center();
            } else {
                wheight = Ext.dom.Element.getViewportHeight();
                wwidth = Ext.dom.Element.getViewportWidth();
                loginpagepc.setWidth(wwidth < 900 ? 900 : wwidth - 1);
                loginpagepc.setHeight(wheight < 600 ? 600 : wheight - 1);
            }
            if (wheight < 768) {
                loginpagep0.addCls("loginpage-panel2");
            } else {
                loginpagep0.removeCls("loginpage-panel2");
            }
            if (panelFormContainer)
                panelFormContainer.center();
        };
        Ext.on('resize', handleLoginPageResize);
        handleLoginPageResize();
        sendAjaxDataJsonRpcL("rest/systemreq/isSignUser", null, null, function (data) {
            if (data.status) {
                data = data.data;
                if (data) {
                    window.location = _HandleRedirectDispatcherURL;
                } else {
                    Ext.get('loadpage-loading-mask').fadeOut({
                        remove: true,
                        duration: 900,
                        scope: this,
                        callback: function () {
                            Ext.get('loginpage-panel').setVisible(true);
                            var itemsFF = [];
                            itemsFF.push({
                                id: "flogin",
                                xtype: 'textfield',
                                fieldLabel: Lang.login,
                                value: Config.defaultlogin || "",
                                inputAttrTpl: 'autocapitalize="off" autocorrect="off"'
                            });
                            itemsFF.push({
                                id: "fpass",
                                xtype: 'textfield',
                                inputType: "password",
                                fieldLabel: Lang.haslo,
                                value: Config.defaultpass || ""
                            });
                            itemsFF.push({
                                id: 'frememberme',
                                xtype: 'checkboxexd',
                                boxLabel: Lang.pamietaj_mnie
                            });
                            if (Const.OAUTH2ENABLED) {
                                var oauthContainer = new Ext.panel.Panel({
                                    id: 'oauth-container',
                                    xtype: 'panel',
                                    layout: {
                                        type: 'hbox',
                                        align: 'stretch'
                                    },
                                    items: [{
                                        id: 'oauthazure',
                                        xtype: 'box',
                                        html: '<a href="' + Config.oauthazureurl + '" class="azure-login-button auth-btn"></a>'
                                    },
                                        // {
                                        //     id: 'oauthgoogle',
                                        //     xtype: 'box',
                                        //     html: '<a href="' + Config.oauthgoogleurl + '" class="google-login-button auth-btn"></a>'
                                        // }
                                    ]
                                });
                                itemsFF.push(oauthContainer);
                            }
                            var fp = new Ext.form.Panel({
                                anchor: 0,
                                frame: false,
                                border: false,
                                id: 'form-login',
                                cls: "form-login",
                                items: itemsFF,
                                buttons: [{
                                    id: 'reset-btn',
                                    align: 'left',
                                    text: Lang.resetuj_haslo,
                                    clickEvent: 'click',
                                    cls: 'btn btn-outline-secondary',
                                    handler: resetPassword,
                                    hidden: true
                                }, {
                                    text: Lang.ok,
                                    id: 'login-btn',
                                    clickEvent: "click",
                                    cls: 'btn btn-outline-secondary',
                                    handler: submitbtn
                                }, {
                                    text: Lang.rejestruj_firme,
                                    id: 'firm-rej-btn',
                                    clickEvent: "click",
                                    cls: 'btn btn-outline-secondary',
                                    handler: refjestrfirmbtn,
                                    hidden: !Const.ALLOW_UNAUTHORIZED_FIRM_CREATION
                                }]
                            });
                            var pn = new Ext.panel.Panel({
                                frame: true,
                                id: 'login-panel-form-container',
                                cls: "panel-login",
                                bodyStyle: "padding:10px;",
                                width: 360,
                                items: [fp],
                                renderTo: "loginpage-panels"
                            });
                            var map = new Ext.util.KeyMap({
                                target: Ext.getBody(),
                                key: Ext.event.Event.ENTER,
                                fn: function () {
                                    enterHandler();
                                }
                            });
                            pn.center();
                        }
                    });
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    });

    var resetPassword = function () {
        let resetWindow = Ext.create('WindowResetPassword');
        resetWindow.showW();
        resetWindow.on('resetSuccess', () => Ext.getCmp('reset-btn').setHidden(true));
        resetWindow.on('closeSuccess', () => {
            enterHandler = submitbtn
        });
    };

    var submitbtn = function () {
        var isRememberMe = Ext.getCmp('frememberme').getValue();
        let loginPanel = Ext.getCmp('form-login');
        loginPanel.mask(Lang.ladowanie);
        performAjaxLPromise("rest/systemreq/performSign", {
            _CancelAutoRedirect: true,
            login: Ext.getCmp("flogin").getValue(),
            pass: Ext.getCmp("fpass").getValue(),
            rememberme: isRememberMe
        }).then(() => {
            window.location = _HandleRedirectDispatcherURL + "?" + "signoperation=true";
        }).catch(error => {
            loginPanel.unmask();
            Ext.getCmp('reset-btn').setHidden(false);
            Ext.getCmp('firm-rej-btn').setHidden(true);
            handleCommonCallBackAjaxRPCError(error);
        });
    };
    var refjestrfirmbtn = function () {
        var windowObject = Ext.create('WindowFirmaExternal');
        windowObject.showW();
    }

    return <></>
}