Ext.define('PanelStanCzytnikaExd', {
    extend: 'Ext.panel.Panel',
    alias: "widget.panelstanczytnikaexd",
    layout: {
        type: 'hbox',
        align: 'center'
    },
    bodyStyle: {
        background: '#f1f1f1',
        padding: '5px'
    },
    frame: true,
    items: [{
        xtype: 'displayfield',
        itemId: "komunikatybox",
        value: "",
        flex: 1,
        fieldStyle: "text-align:center"
    }, {
        xtype: "box",
        itemId: "pastylkaident",
        cls: "x-czytnik-card-panel-card",
        userCls: 'x-czytnik-iconCzytnikError'
    }],
    setCommunicationState: function (ikonIdentCls, message) {
        if (this && this.items && this.down("#pastylkaident")) {
            this.down("#pastylkaident").setUserCls(ikonIdentCls);
            if (message !== null) {
                var komunikat = this.down("#komunikatybox").setValue(message);
            }
        }
    },
    onOk: function () {
        this.setCommunicationState("x-czytnik-ikonCard", Lang.prosze_przylozyc_karte);
    },
    onCommError: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", Lang.czytnik_error_comm);
    },
    onIdentPut: function (czytnik, karta) {
        if (karta.ident != null) {
            sendAjaxDataJsonRpcL("rest/systemkonf/checkIsCanAssignIdentToObject", {
                id: this.obiektid,
                objecttype: this.objectt,
                identyfikator: karta.ident,
                identtype: this.oidenttype
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    if (data.canassign && data.haskod) {
                        this.setCommunicationState("x-czytnik-ikonLogin", Lang.identyfikator_przydzielony_poprawnie);
                        this.oident = karta.ident;
                        //managerCzytnikPastylka.un("identput", this.onIdentPut, this);
                        //managerCzytnikPastylka.un("identpull", this.onIdentPull, this);
                        this.fireEvent('iassign', karta.ident);

                    } else {
                        this.setCommunicationState("x-czytnik-iconLoginError", Lang.identyfikator_nie_moze_zostac_przydzielony);

                    }
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                    this.setCommunicationState("x-czytnik-iconLoginError", Lang.identyfikator_nie_moze_zostac_przydzielony);
                }
            });
        }
    },
    onIdentPull: function (czytnik) {
        this.setCommunicationState("x-czytnik-ikonCard", Lang.prosze_przylozyc_karte);
    },
    onNoService: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", checkIfHttpsRenderCzytnikFixBtn());
    },
    onIncorrectVersion: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", Lang.czytnik_zla_wersja_oprogramowania);
    },
    onNotOpen: function () {
        this.setCommunicationState("x-czytnik-iconCzytnikError", Lang.czytnik_open_error);
    },
    getIdentifier: function () {
        return this.oident;
    },
    initSocket: function (params) {
        if (params.oidenttype == null) {
            this.ident_object = params.sysidentparams.ident_operator;
            managerCzytnikPastylka.init();
            managerCzytnikPastylka.on({
                scope: this,
                ok: this.onOk,
                commerror: this.onCommError,
                identput: this.onIdentPut,
                identpull: this.onIdentPull,
                noservice: this.onNoService,
                incorrectversion: this.onIncorrectVersion,
                notopen: this.onNotOpen
            });
            this.setCommunicationState('x-czytnik-ikonCard', Lang.czytnik_oczekiwanie_na_polaczenie);
            this.oidenttype = this.ident_object;
        } else {
            this.oidenttype = params.oidenttype;
        }
    },
    init: function (params) {
        Ext.apply(this, params);
        Ext.apply(this.params, params);
        this.initSocket(params);
    },
    close: function () {
        managerCzytnikPastylka.close();
    }
});
