Ext.define('PanelFirmaDetails', {
    extend: 'Ext.panel.Panel',
    alias: 'widget.panelfirmadetails',
    cls: 'a-PanelFirmaDetails',
    id: 'panelfirmadetails',
    firma: null,
    windowObject: null,
    layout: 'fit',
    deviceGrid: null,
    kostkiGrid: null,
    initComponent: function () {
        this.buildDeviceGrid();
        this.buildKostkiGrid()
        this.items = [{
            xtype: 'form',
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0",
                labelWidth: 150,
                labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
            },
            frame: true,
            border: false,
            autoWidth: true,
            scrollable: true,
            autoHeight: true,
            items: [{
                xtype: 'toolbar',
                docked: 'top',
                padding: 10,
                scrollable: WindowExdMobileDEVICEV,
                margin: '0 0 10 0',
                defaults: {
                    xtype: 'button',
                    iconAlign: 'left'
                },
                items: [{
                    itemId: 'core-btn',
                    iconCls: 'core-icon',
                    text: Lang.przejdz_do_core,
                    maxWidth: 250,
                    listeners: {
                        scope: this,
                        click: function () {
                            window.location = Config.prefixportalcorelink;
                        }
                    },
                    disabled: !validPrivilegesOperatorComplex("access.view.ccore")
                }, {
                    iconCls: 'edit-icon',
                    text: Lang.edytuj,
                    disabled: isValidOperatorFirmaRaw() || !validPrivilegesOperatorComplex(ViewFirmas.accessPropertyManageName),
                    listeners: {
                        scope: this,
                        click: this.edytujObiekt
                    }
                }, {
                    iconCls: 'receipt-icon',
                    text: Lang.pokaz_rachunki,
                    listeners: {
                        scope: this,
                        click: () => this.openWindow('WindowRachunki')
                    },
                    disabled: !validPrivilegesOperatorComplex(ViewRachunki.accessPropertyViewName)
                }, {
                    itemId: 'zasilenie-btn',
                    text: Lang.zasilenie,
                    iconCls: 'kostka-add-icon',
                    listeners: {
                        scope: this,
                        click: () => this.openKostkaWindow('WindowKostkiZasKonw', false)
                    },
                    hidden: isValidOperatorFirmaRaw()
                }, {
                    itemId: 'konwersja-btn',
                    text: Lang.konwersja,
                    iconCls: 'kostka-change-icon',
                    listeners: {
                        scope: this,
                        click: () => this.openKostkaWindow('WindowKostkiZasKonw', true)
                    },
                    hidden: isValidOperatorFirmaRaw()
                }, {
                    itemId: 'wylacz-firme-btn',
                    text: Lang.wylacz_firme,
                    iconCls: 'cancel-icon',
                    listeners: {
                        scope: this,
                        click: this.wylaczFirme
                    },
                    hidden: isValidOperatorFirmaRaw(),
                    disabled: !validPrivilegesOperatorComplex("access.manage.cfirmy.deactivate")
                }]
            }, {
                itemId: "nazwa",
                name: "nazwa",
                xtype: 'textfieldexd',
                fieldLabel: Lang.nazwa,
                editable: false,
                allowBlank: true
            }, {
                labelStyle: "margin-top:10px",
                labelWidth: 150,
                style: "margin-top:10px",
                anchor: "0",
                itemId: "opis",
                name: "opis",
                editable: false,
                allowBlank: true,
                isFormField: true,
                fieldLabel: Lang.opis,
                xtype: 'textareaexd'
            }, {
                itemId: "adres",
                name: "adres",
                xtype: 'textareaexd',
                fieldLabel: Lang.adres,
                editable: false,
                allowBlank: true
            }, {
                itemId: "telefon",
                name: "telefon",
                xtype: 'textfieldexd',
                fieldLabel: Lang.telefon,
                editable: false,
                allowBlank: true
            }, {
                itemId: "nip",
                name: "nip",
                xtype: 'textfieldexd',
                fieldLabel: Lang.nip,
                editable: false,
                allowBlank: true
            }, {
                itemId: "kraj",
                name: "kraj",
                xtype: 'textfieldexd',
                fieldLabel: Lang.kraj,
                editable: false,
                allowBlank: true
            }, {
                itemId: "email",
                name: "email",
                xtype: 'textfieldexd',
                fieldLabel: Lang.email,
                editable: false,
                allowBlank: true
            }, {
                itemId: "lang",
                name: "lang",
                xtype: 'textfieldexd',
                store: new Ext.data.ArrayStore({
                    data: [],
                    fields: ['value', 'text']
                }),
                fieldLabel: Lang.jezyk,
                allowBlank: true,
                displayField: 'text',
                valueField: 'value',
                resizable: false,
                editable: false
            }, {
                itemId: "timezone",
                name: "timezone",
                xtype: 'textfieldexd',
                store: new Ext.data.ArrayStore({
                    data: [],
                    fields: ['value', 'text']
                }),
                fieldLabel: Lang.strefa_czasowa,
                allowBlank: true,
                displayField: 'text',
                valueField: 'value',
                resizable: false,
                editable: false
            }, {
                xtype: 'container',
                layout: {
                    type: 'hbox',
                    align: WindowExdMobileDEVICEV ? 'bottom' : 'left',
                    pack: WindowExdMobileDEVICEV ? 'bottom' : 'left'
                },
                padding: '0 0 10 0',
                defaults: {
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    itemId: "typSystemu",
                    name: "typSystemu",
                    xtype: 'textfieldexd',
                    labelWidth: 150,
                    margin: '0 10 0 0',
                    store: new Ext.data.ArrayStore({
                        data: TYP_SYSTEMU_DATA,
                        fields: ['value', 'text']
                    }),
                    fieldLabel: Lang.typ_systemu,
                    resizable: false,
                    editable: false,
                    allowBlank: true
                }, {
                    itemId: 'typSystemu-btn',
                    xtype: 'button',
                    margin: '0 0 0 10',
                    width: 80,
                    text: Lang.zmien,
                    listeners: {
                        scope: this,
                        click: () => this.openWindow('WindowKreatorZmienTypSystemu')
                    },
                    hidden: isValidOperatorFirmaRaw(),
                    disabled: !validPrivilegesOperatorComplex("access.manage.cbillingi.systype")
                }]
            }, {
                xtype: 'container',
                layout: {
                    type: 'hbox',
                    align: WindowExdMobileDEVICEV ? 'bottom' : 'left',
                    pack: WindowExdMobileDEVICEV ? 'bottom' : 'left'
                },
                padding: '0 0 10 0',
                defaults: {
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    itemId: 'customModFlag',
                    name: 'customModFlag',
                    fieldLabel: Lang.modyfikacja_licencji,
                    labelWidth: 150,
                    xtype: 'textfieldexd',
                    resizable: false,
                    editable: false
                }]
            }, {
                xtype: 'container',
                layout: {
                    type: 'hbox',
                    align: WindowExdMobileDEVICEV ? 'bottom' : 'left',
                    pack: WindowExdMobileDEVICEV ? 'bottom' : 'left'
                },
                padding: '0 0 10 0',
                defaults: {
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    itemId: "billblocked",
                    labelWidth: 150,
                    name: "billblocked",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.zablokowano,
                    allowBlank: true,
                    editable: false
                }, {
                    itemId: 'billblocked-btn',
                    xtype: 'button',
                    hidden: isValidOperatorFirmaRaw(),
                    margin: '0 0 0 20',
                    width: 80,
                    listeners: {
                        scope: this,
                        click: this.setBillBlocked
                    },
                    disabled: !validPrivilegesOperatorComplex("access.manage.cbillingi.block")
                }]
            }, {
                xtype: "container",
                layout: {
                    type: "vbox",
                    align: "stretch"
                },
                flex: 1,
                items: [this.deviceGrid]
            }, {
                xtype: "container",
                layout: {
                    type: "vbox",
                    align: "stretch"
                },
                flex: 1,
                items: [this.kostkiGrid]
            }]
        }];
        this.refreshData();
        this.callParent(arguments);
    },
    refreshData: function () {
        performAjaxLPromise('rest/systemkonf/readTimezoneList').then(data => {
            let timezones = data.map(tz => [tz.value, tz.text]);
            this.down('#timezone').store.loadData(timezones);
            return performAjaxLPromise('rest/systemkonf/readLangList')
        }).then(data => {
            let languages = data.map(lang => [lang.value, lang.text]);
            this.down('#lang').store.loadData(languages);
            return performAjaxLPromise('rest/dashboard/getFullInfo')
        }).then(data => {
            let {nazwa, opis, adres, telefon, nip, kraj, email, lang, timezone, active, custommodflag} = data.firma;
            let {typSystemu, billblocked, typSystemuTrial} = data.firma.billingInfo;
            this.firma = data.firma;
            this.down("#nazwa").setValue(nazwa);
            this.down("#opis").setValue(opis);
            this.down("#adres").setValue(adres);
            this.down("#telefon").setValue(telefon);
            this.down("#nip").setValue(nip);
            this.down('#kraj').setValue(kraj);
            this.down("#email").setValue(email);
            let system;
            if (typSystemuTrial == null) {
                system = TYP_SYSTEMU_DATA.find(type => type[0] == typSystemu)[1];
            } else {
                system = `${TYP_SYSTEMU_DATA.find(type =>
                    type[0] == typSystemu)[1]} / ${TYP_SYSTEMU_DATA.find(type => type[0] == typSystemuTrial)[1]} (trial)`
            }
            this.down("#typSystemu").setValue(system);
            if (typSystemu == Const.TYP_SYSTEMU_STARTER) {
                this.down('#zasilenie-btn').setDisabled(true);
                this.down('#konwersja-btn').setDisabled(true);
            }
            this.down('#customModFlag').setValue(CUSTOM_MOD_FLAG_DATA.find(flag => flag[0] == custommodflag)[1]);
            this.down("#lang").setValue(this.down("#lang").store.getData().items.find(type => type.data.value == lang).data.text);
            this.down("#timezone").setValue(this.down("#timezone").store.getData().items.find(type => type.data.value == timezone).data.text);
            this.down('#billblocked').setValue(billblocked ? Lang.tak : Lang.nie);
            this.down('#billblocked-btn').setText(billblocked ? Lang.odblokuj : Lang.zablokuj);
            this.down('#wylacz-firme-btn').setText(active ? Lang.wylacz_firme : Lang.wlacz_firme);
            this.down('#core-btn').setDisabled((isValidOperatorFirmaRaw() && billblocked) || !validPrivilegesOperatorComplex("access.view.ccore"));
            return performAjaxLPromise('rest/magazyn/get')
        }).then(data => {
            let magazynDevices = data.magazynDevices;
            let magazynKostki = data.magazynKostki;
            this.deviceGrid.store.loadData(magazynDevices);
            this.kostkiGrid.store.loadData(magazynKostki);
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        }).finally(() => {
            //this.unmask();
        });
    },
    edytujObiekt: function () {
        windowObject = Ext.create('WindowFirma', {dashboard: true});
        windowObject.down('#typSystemu').setVisible(false);
        windowObject.showW();
        windowObject.on('closeSuccess', this.refreshData, this);
    },
    wylaczFirme: function () {
        let active = this.firma.active;
        let headerMessage, message;
        if (active) {
            headerMessage = Lang.wylacz_firme;
            message = Lang.czy_chcesz_wylaczyc_firme;
        } else {
            headerMessage = Lang.wlacz_firme;
            message = Lang.czy_chcesz_wlaczyc_firme;
        }
        Ext.create('Ext.ux.window.MessageBoxMobileExd').confirm(headerMessage, message, btn => {
            if (btn.toLowerCase() == 'yes') {
                performAjaxLPromise('rest/firmy/setActiveForCurrentFirm', {active: !active}).then(() => {
                    let viewFirmaBtn = Ext.getCmp('firmas').buttonId;
                    Ext.getCmp('dashboard').resetDashboard();
                    Ext.getCmp(viewFirmaBtn).handler();
                }).catch(error => {
                    handleCommonCallBackAjaxRPCError(error);
                });
            }
        });
    },
    setBillBlocked: function () {
        let billblocked = this.firma.billingInfo.billblocked;
        let headerMessage = billblocked ? Lang.odblokuj_firme : Lang.zablokuj_firme;
        Ext.create('Ext.ux.window.MessageBoxMobileExd').confirm(headerMessage, billblocked ? Lang.czy_odblokowac_firme : Lang.czy_zablokowac_firme,
            btn => {
                if (btn.toLowerCase() == 'yes') {
                    this.mask(Lang.ladowanie);
                    performAjaxLPromise('rest/billing/setBillBlockedForCurrentFirm', {billblocked: !billblocked}).then(() => {
                        Ext.create('Ext.ux.window.MessageBoxMobileExd').alert(Lang.sukces, billblocked ? Lang.odblokowano_pomyslnie : Lang.zablokowano_pomyslnie);
                        this.refreshData();
                    }).catch(error => {
                        handleCommonCallBackAjaxRPCError(error);
                    }).finally(() => {
                        this.unmask();
                    });
                }
            }
        );
    },
    openWindow: function (window) {
        windowObject = Ext.create(window);
        windowObject.showW();
        windowObject.on('closeSuccess', this.refreshData, this);
    },
    openKostkaWindow: function (window, iskonversion) {
        windowObject = Ext.create(window, {
            iskonversion: iskonversion
        });
        windowObject.showW();
        windowObject.on('closeSuccess', this.refreshData, this);
    },
    buildDeviceGrid: function () {
        this.deviceGrid = new Ext.ux.grid.GridPanelExd({
            itemId: 'billgrid',
            minHeight: 200,
            store: Ext.create('Ext.data.Store', {
                autoLoad: false,
                remoteSort: false,
                remoteFilter: false,
                model: Ext.define(null, {
                    extend: 'Ext.data.Model',
                    fields: ["expireDate", "paid", "fbs", {
                        name: "uuid",
                        calculate: function (row) {
                            return row.fbs.uuid;
                        }
                    }, {
                        name: "typKostki",
                        calculate: function (row) {
                            return row.fbs.kostkaname;
                        }
                    }, {
                        name: "serialnumber",
                        calculate: function (row) {
                            return row.fbs.serialnumber;
                        }
                    }, {
                        name: "additionalfeatures",
                        calculate: function (row) {
                            return row.fbs.additionalfeatures;
                        }
                    }, {
                        name: "typurzadzenia",
                        calculate: function (row) {
                            return row.fbs.typurzadzenia;
                        }
                    }, {
                        name: "typsystemu",
                        calculate: function (row) {
                            return row.fbs.typsystemu;
                        }
                    }]
                }),
                proxy: {
                    type: 'memory',
                    reader: {
                        type: 'json'
                    }
                },
                sorters: [{
                    property: "expireDate",
                    direction: "ASC"
                }]
            }),
            viewConfig: {
                forceFit: true,
                getRowClass: function (record, index) {
                    if (!record.data.paid) {
                        return 'rowRed';
                    } else {
                        return '';
                    }
                }
            },
            columns: [{
                header: "UUID",
                width: 145,
                sortable: true,
                dataIndex: 'uuid',
                filter: {
                    type: 'string'
                },
                hidden: true
            }, {
                header: Lang.typ_urzadzenia,
                flex: 1,
                sortable: true,
                dataIndex: 'typurzadzenia',
                filter: {
                    type: 'string'
                },
                renderer: this.typUrzadzeniaRenderer
            }, {
                header: Lang.typ_systemu,
                flex: 1,
                sortable: true,
                dataIndex: 'typsystemu',
                filter: {
                    type: 'numeric'
                },
                renderer: this.typSystemuRenderer
            }, {
                header: Lang.numer_seryjny,
                dataIndex: "serialnumber",
                type: 'auto',
                flex: 1
            }, {
                header: Lang.typ_kostki,
                dataIndex: "typKostki",
                type: 'auto',
                flex: 1
            }, {
                header: Lang.opcje,
                flex: 1,
                dataIndex: 'additionalfeatures'
            }, {
                header: Lang.data_wygasniecia,
                dataIndex: "expireDate",
                type: 'auto',
                flex: 1
            }],
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            },
            plugins: {
                gridfilters: true,
                rowexpander: !WindowExdMobileDEVICEV ? null : {
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">UUID:
                                <span class="mobile-rowexp-value"> {uuid}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.typ_urzadzenia}:
                                <span class="mobile-rowexp-value"> {typurzadzenia:this.typUrzadzenia}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.typ_systemu}:
                                <span class="mobile-rowexp-value"> {typsystemu:this.typSystemu}</span>
                            </span>
                        </div>

                        <tpl if="this.notEmptyAfterTrim(additionalfeatures)">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.opcje}:
                                    <span class="mobile-rowexp-value"> {additionalfeatures}</span>
                                </span>
                            </div>
                        </tpl>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.numer_seryjny}:</span>
                            <span class="mobile-rowexp-value"> {serialnumber}</span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.data_wygasniecia}:</span>
                            <span class="mobile-rowexp-value"> {expireDate}</span>
                        </div>
                    </div>`,
                        {
                            typUrzadzenia: this.typUrzadzeniaRenderer,
                            typSystemu: this.typSystemuRenderer,
                            notEmptyAfterTrim
                        }
                    )
                }
            }
        });
    },
    buildKostkiGrid: function () {
        this.kostkiGrid = new Ext.ux.grid.GridPanelExd({
            itemId: 'kostkigrid',
            minHeight: 200,
            store: Ext.create('Ext.data.Store', {
                autoLoad: false,
                remoteSort: false,
                remoteFilter: false,
                model: Ext.define(null, {
                    extend: 'Ext.data.Model',
                    fields: ["kostkaDto", {
                        name: "kostkaname",
                        calculate: function (row) {
                            return row.kostkaDto.kostkaname;
                        }
                    }, {
                        name: "ilosckostek",
                        calculate: function (row) {
                            return row.kostkaDto.ilosckostek;
                        }
                    }]
                }),
                proxy: {
                    type: 'memory',
                    reader: {
                        type: 'json'
                    }
                },
                sorters: [{
                    property: "expireDate",
                    direction: "ASC"
                }]
            }),
            columns: [{
                header: Lang.typ_kostki,
                flex: 2,
                sortable: true,
                dataIndex: 'kostkaname',
                filter: {
                    type: 'string'
                }
            }, {
                header: Lang.ilosc_kostek,
                flex: 2,
                sortable: true,
                dataIndex: 'ilosckostek',
                filter: {
                    type: 'numeric'
                }
            }]
        });
    },
    typSystemuRenderer: function (value) {
        return TYP_SYSTEMU_DATA.find(type => type[0] == value)[1]
    },
    typUrzadzeniaRenderer: function (value) {
        return TYP_URZADZENIA_DATA.find(type => type[0] == value)[1]
    }
});