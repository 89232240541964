
      import API from "!../../../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      
      import setAttributes from "!../../../../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../../../node_modules/css-loader/dist/cjs.js!./WindowOrderDetails.css";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = function (element) {
                            let divmarker = document.querySelector('.style-marker-insert-before');
                            if (divmarker) {
                                divmarker.parentNode.insertBefore(element, divmarker);
                            } else {
                                document.body.appendChild(element);
                            }
                        };
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../../../node_modules/css-loader/dist/cjs.js!./WindowOrderDetails.css";
       export default content && content.locals ? content.locals : undefined;
