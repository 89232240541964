window._HandleRedirectDispatcherURL = "redirector";
window._HandleLangCommunicationError = Lang.brak_komunikacji_z_serwerem;
window._HandleLangError = Lang.error;
window._HandleStandardExceptionCToServer_AJAX_URL = "rest/systemreq/handleGUIException";
window._HandleStandardExceptionAlert = Const.DEBUG;
window._HandleStandardExceptionReloadPage = false; //!Const.DEBUG;
window._HandleStandardExceptionAlertOnError = Const.DEBUG;
window._HandleStandardExceptionReloadPageOnError = false; //!Const.DEBUG;

window.AppUseAppCookieAsState = false;
window.AppCpCookie = null;
window.AppCpState = null;
window.AppCLocationParams = {};
window.AppCommonData = {};
window.AppCommonDataEvents = new Ext.util.Observable({});
window.AppNameCApp = "application";

window.AppOPERATOR = null;
window.AppOPERATORROLA = 0;
window.AppOPERATORFIRMA = null;
window.AppOPERATORMNGFIRMAID = null;
window.AppOPERATORHPOZIOM = 10;


window.saveGuiBIUIState = (c, state, callback, scope) => {
    sendAjaxDataJsonRpcL("rest/system/saveGuiBIUIState", {
        context: c,
        state: state
    }, null, function (data) {
        if (data.status) {
            if (callback != null)
                callback.call(scope, true);
        } else {
            if (callback != null)
                callback.call(scope, false);
        }
    }, 0);
}

window.readGuiBIUIState = (c, callback, scope, defaultv) => {
    sendAjaxDataJsonRpcL("rest/systemreq/readGuiBIUIState", {
        context: c
    }, null, function (data) {
        if (data.status) {
            var state = datad;
            if (defaultv != undefined && state == null)
                state = defaultv;
            if (callback != null)
                callback.call(scope, state, true);
        } else {
            if (callback != null)
                callback.call(scope, null, false);
        }
    }, 0);
}

window.initGUILocationHash = () => {
    try {
        var token = window.location.hash.substr(1);
        AppCLocationParams = Ext.Object.fromQueryString(token);
    } catch (exp) {
    }
    return AppCLocationParams;
}

window.updateGUILocationHash = (o) => {
    try {
        AppCLocationParams = Ext.apply(AppCLocationParams, o);
        var urle = Ext.Object.toQueryString(AppCLocationParams);
        window.location.hash = '#' + urle;
    } catch (exp) {
    }
    return AppCLocationParams;
}

window.retriveCompleteGUILocationHash = (o) => {
    try {
        o = Ext.applyIf(o, AppCLocationParams);
        var urle = Ext.Object.toQueryString(o);
        return '#' + urle;
    } catch (exp) {
    }
}

var ExtonReadyMainExecutedFirst = false;

window.applicationStartExd = (capp, handler) => {
    Ext.onReady(function () {
        if (ExtonReadyMainExecutedFirst) {
            return;
        }
        ExtonReadyMainExecutedFirst = true;
        AppNameCApp = capp;
        if (!Ext.supports.Touch) {
            Ext.tip.QuickTipManager.init();
        }
        if (!Ext.util.History.ready) {
            Ext.util.History.init();
        }
        initGUILocationHash();
        AppCpCookie = Ext.create('Ext.state.CookieProvider', {
            path: "/",
            expires: new Date(new Date().getTime() + (1000 * 60 * 60 * 24 * 30)),
            domain: null
        });
        sendAjaxDataJsonRpcLGroup(["rest/systemreq/getSignUser", "rest/systemreq/readGuiState"], [null, {
            context: AppNameCApp
        }], window, function (gstatus, data, datad, status) {
            if (gstatus) {
                if (datad[0]) {
                    AppOPERATOR = datad[0].operator;
                    AppOPERATORHPOZIOM = datad[0].operator.hpoziom;
                    AppOPERATORROLA = datad[0].operator.role;
                    AppOPERATORFIRMA = datad[0].firmaOperator;
                    AppOPERATORMNGFIRMAID = datad[0].operator.firmaZarzadzajacaId;
                    var initState = datad[1];
                    AppCpState = Ext.create("Ext.ux.StateHTTPProviderExd", {
                        context: AppNameCApp,
                        useInitState: true,
                        initState: initState,
                        readParams: function () {
                            try {
                                sendAjaxDataJsonRpcL("rest/systemreq/readGuiState", {
                                    context: this.context
                                }, this, function (data, datad, status) {
                                    try {
                                        var state = Ext.decode(datad);
                                        this.state = ((state == null || !Ext.isObject(state)) ? {} : state);
                                    } catch (exception) {
                                        this.state = {};
                                    }
                                    if (this.callback != null)
                                        this.callback.call(this.scope);
                                }, 0);
                            } catch (exception) {
                            }
                        },
                        writeParams: function () {
                            try {
                                sendAjaxDataJsonRpcL("rest/systemreq/saveGuiState", {
                                    context: this.context,
                                    state: Ext.encode(this.state)
                                }, 0);
                            } catch (exception) {
                            }
                        }
                    });
                    if (AppUseAppCookieAsState) {
                        Ext.state.Manager.setProvider(AppCpCookie);
                    } else {
                        Ext.state.Manager.setProvider(AppCpState);
                    }
                    Ext.defer(function () {
                        if (Ext.get('loadpage-loading-msg') != null)
                            Ext.get('loadpage-loading-msg').fadeOut({
                                remove: true,
                                duration: 200
                            });
                        if (Ext.get('loadpage-loading-mask') != null)
                            Ext.get('loadpage-loading-mask').fadeOut({
                                remove: true,
                                duration: 900
                            });
                    }, 1000);
                    if (handler != null) {
                        handler.call();
                    }
                } else {
                    window.location = _HandleRedirectDispatcherURL;
                }
            } else {
                window.location = _HandleRedirectDispatcherURL;
            }
        }, 0);
    });
}
