Ext.define('PanelIdentyfikatora', {
    extend: 'Ext.form.FieldContainer',
    alias: "widget.identyfikatorpanel",
    baseCls: 'x-ident-panel',
    labelCls: 'x-ident-panel-label',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'displayexd',
            baseBodyCls: 'x-ident-ikonNone x-ident-panel-body',
            id: this.cid + 'iconpanel'
        }];
        this.layout = 'anchor';
        this.defaults = {
            layout: '100%'
        };
        this.frame = false;
        this.border = false;
        this.validateFkodB = Ext.Function.createBuffered(this.validateFkod, 200, this);
        this.callParent(arguments);
    },
    init: function (params) {
        Ext.apply(this, params);
        Ext.apply(this.params, params);
        Ext.suspendLayouts();
        this.processConfig(params);
        Ext.resumeLayouts(true);
    },
    getIdentData: function () {
        var pinn = Ext.getCmp(this.cid + 'pin1').getValue();
        return {
            identyfikator: this.oident,
            identtype: this.oidenttype,
            pin: pinn
        };
    },
    isValid: function () {
        var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
        if (this.oident != null) {
            return Ext.getCmp(this.cid + 'pin1').getValue() != null && Ext.getCmp(this.cid + 'pin1').getValue() != '';
        } else
            return false;
    },
    isValidOtherErrorIf: function () {
        var pin1 = Ext.getCmp(this.cid + 'pin1');
        if (pin1.getValue().trim() == "" || !pin1.isValid()) {
            pin1.markInvalid(Lang.kod_pin_musi_miec_n_cyfr);
            var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
            if (iconPanel && iconPanel.bodyEl)
                iconPanel.bodyEl.replaceCls('x-ident-ikonOk', 'x-ident-ikonNone');
            return Lang.kod_pin_musi_miec_n_cyfr;
        }
        return null;
    },
    onPinValidityChange: function (component, isValid, eventOptions) {
        var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
        if (isValid) {
            if (this.oident != null && iconPanel && iconPanel.bodyEl) {
                iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
            } else if (iconPanel && iconPanel.bodyEl) {
                iconPanel.bodyEl.replaceCls('x-ident-ikonOk', 'x-ident-ikonNone');
            }
        } else if (iconPanel && iconPanel.bodyEl) {
            iconPanel.bodyEl.replaceCls('x-ident-ikonOk', 'x-ident-ikonNone');
        }
    },
    processConfig: function (params) {
        this.oident = params.object != null ? params.object.identyfikator : null;
        this.oidenttype = params.object != null ? params.object.identtype : null;
        this.ident_object = params.sysidentparams.ident_operator;
        this.readerPanel.init({
            obiektid: this.obiektid,
            objectt: this.objectt,
            ident_object: this.ident_object,
            sysidentparams: {
                "ident_tryb": Const.IDENT_ELEKTRONICZNAPIN,
                "ident_operator": Const.IDENT_ELEKTRONICZNAPIN
            }
        });
        this.readerPanel.on('iassign', function (id) {
            this.oident = id;
            var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
            if (Ext.getCmp(this.cid + "pin1").isValid() && iconPanel && iconPanel.bodyEl) {
                iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
            }
        }, this);
        this.oidenttype = this.ident_object;
        var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
        this.add({
            id: this.cid + "pin1",
            fieldLabel: Lang.pin,
            labelAlign: 'top',
            xtype: 'textfieldexd',
            value: '0000',
            width: 120,
            regex: new RegExp("^[0-9]{4,4}$"),
            regexText: Lang.kod_pin_musi_miec_n_cyfr,
            allowBlank: false,
            blankText: Lang.kod_pin_musi_miec_n_cyfr,
            listeners: {
                scope: this,
                validitychange: this.onPinValidityChange
            }
        });
        if (this.object) {
            if (this.object.pin == null || this.object.pin == "") {
                this.object.pin = '0000';
            }
            Ext.getCmp(this.cid + "pin1").setValue(this.object.pin);
        }
        if (this.oident != null && iconPanel && iconPanel.bodyEl) {
            iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
        }
    },
    markIdAsUsed: function () {
        var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
        if (iconPanel && iconPanel.bodyEl)
            iconPanel.bodyEl.replaceCls('x-ident-ikonOk', 'x-ident-ikonNone');
        Ext.getCmp(this.cid + 'pin1').markInvalid(Lang.identyfikator_uzywany);
        this.oident = null;
    },
    validateFkod: function (component) {
        if (component.isValid()) {
            var iconPanel = Ext.getCmp(this.cid + 'iconpanel');
            sendAjaxDataJsonRpcL("rest/systemkonf/checkIsCanAssignIdentToObject", {
                id: this.obiektid,
                objecttype: this.objectt,
                identyfikator: component.getValue(),
                identtype: this.oidenttype
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    if (data.canassign && iconPanel && iconPanel.bodyEl) {
                        iconPanel.bodyEl.replaceCls('x-ident-ikonNone', 'x-ident-ikonOk');
                    } else {
                        if (component)
                            component.markInvalid(Lang.identyfikator_uzywany);
                        if (iconPanel && iconPanel.bodyEl)
                            iconPanel.bodyEl.replaceCls('x-ident-ikonOk', 'x-ident-ikonNone');
                    }
                }
            });
        }
    },
    close: function () {
        if (this.readerPanel != null) {
            this.readerPanel.close();
        }
    }
});
