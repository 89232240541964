Ext.define('ViewRachunkiMobile', {
    extend: 'Ext.panel.Panel',
    grid: null,
    windowObject: null,
    id: "rachunki",
    rowContext: null,
    showState: false,
    statics: {
        accessPropertyManageName: "access.manage.crachunki",
        accessPropertyViewName: "access.view.crachunki"
    },
    cat: "p",
    text: Lang.rachunki,
    buttonIconCls: "iconRachunek",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            scrollable: 'x',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    buildGrid: function () {
        var columnsArray = [{
            header: Lang.numer_rachunku,
            flex: 1,
            sortable: true,
            dataIndex: 'numerrachunku',
            filter: {
                type: 'numeric'
            }
        }, {
            header: Lang.firma,
            flex: 1,
            sortable: true,
            dataIndex: 'firma',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.data_utworzenia,
            flex: 1,
            sortable: true,
            dataIndex: 'datautworzenia',
            filter: {
                type: 'date'
            }
        }, {
            header: Lang.oplacono,
            flex: 1,
            sortable: true,
            dataIndex: 'flagaoplacenia',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            }
        }, {
            header: Lang.zatwierdzono,
            flex: 1,
            sortable: true,
            filterable: true,
            dataIndex: 'flagazatwierdzenia',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            }
        }, {
            header: Lang.faktura_zalaczona,
            flex: 1,
            sortable: true,
            dataIndex: 'flagazalaczeniafaktury',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.faktura_wyslana,
            flex: 1,
            sortable: true,
            dataIndex: 'flagawyslaniafaktura',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.faktura_zalaczona,
            flex: 1,
            sortable: true,
            dataIndex: 'flagazalaczeniaproforma',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.faktura_wyslana,
            flex: 1,
            sortable: true,
            dataIndex: 'flagawyslaniaproforma',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.data_dodania_faktury,
            flex: 1,
            sortable: true,
            dataIndex: 'fakturadatadodania',
            filter: {
                type: 'date'
            }
        }, {
            header: Lang.termin_platnosci,
            flex: 1,
            sortable: true,
            dataIndex: 'fakturaterminplatnosci',
            filter: {
                type: 'numeric'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.operator,
            flex: 2,
            sortable: true,
            dataIndex: 'userimienazwisko',
            filter: {
                type: 'string'
            },
            filterable: true
        }];
        var recordType = [{
            name: 'id',
            mapping: 'id'
        }, {
            name: 'serialnumber',
            mapping: 'serialnumber'
        }, {
            name: 'devid',
            mapping: 'devid'
        }, {
            name: 'version',
            mapping: 'version'
        }, {
            name: 'tmslastrespond',
            mapping: 'tmslastrespond'
        }];
        let store = new Ext.data.Store({
            remoteSort: true,
            remoteFilter: true,
            autoLoad: false,
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: "numerrachunku",
                fields: [{
                    name: 'numerrachunku',
                    mapping: 'numerrachunku'
                }, {
                    name: 'firma',
                    mapping: 'firma'
                }, {
                    name: 'datarozpoczecia',
                    mapping: 'datarozpoczecia'
                }, {
                    name: 'datazakonczenia',
                    mapping: 'datazakonczenia'
                }, {
                    name: 'flagaoplacenia',
                    mapping: 'flagaoplacenia'
                }, {
                    name: 'flagauniewznienia',
                    mapping: 'flagauniewznienia'
                }, {
                    name: 'flagazalaczeniafaktury',
                    mapping: 'flagazalaczeniafaktury'
                }, {
                    name: 'flagawyslaniafaktura',
                    mapping: 'flagawyslaniafaktura'
                }, {
                    name: 'flagazalaczeniaproforma',
                    mapping: 'flagazalaczeniaproforma'
                }, {
                    name: 'flagawyslaniaproforma',
                    mapping: 'flagawyslaniaproforma'
                }, {
                    name: 'fakturadatadodania',
                    mapping: 'fakturadatadodania'
                }, {
                    name: 'fakturaterminplatnosci',
                    mapping: 'fakturaterminplatnosci'
                }, {
                    name: 'userimienazwisko',
                    mapping: 'userimienazwisko'
                }]
            }),
            sorters: [{
                property: "numerrachunku",
                direction: "DESC"
            }],
            proxy: {
                type: 'ajax',
                url: 'rest/billingHistory/getListForAllAvailableFirms',
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'numerrachunku'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true,
                rowexpander: !WindowExdMobileDEVICEV ? null : {
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.numer_rachunku}:
                                <span class="mobile-rowexp-value"> {numerrachunku}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.data_rozpoczecia}:
                                <span class="mobile-rowexp-value"> {datarozpoczecia}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.data_zakonczenia}:
                                <span class="mobile-rowexp-value"> {datazakonczenia}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.data_utworzenia}:
                                <span class="mobile-rowexp-value"> {datautworzenia}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.oplacono}:</span>
                            <span class="mobile-rowexp-value"> {flagaoplacenia:this.yesNo}</span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.zatwierdzono}:</span>
                            <span class="mobile-rowexp-value"> {flagazatwierdzenia:this.yesNo}</span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.faktura_zalaczona}:</span>
                            <span class="mobile-rowexp-value"> {flagazalaczeniafaktury:this.yesNo}</span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.faktura_wyslana}:</span>
                            <span class="mobile-rowexp-value"> {flagawyslaniafaktura:this.yesNo}</span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.proforma_zalaczona}:</span>
                            <span class="mobile-rowexp-value"> {flagazalaczeniaproforma:this.yesNo}</span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.proforma_wyslana}:</span>
                            <span class="mobile-rowexp-value"> {flagawyslaniaproforma:this.yesNo}</span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.data_dodania_faktury}:
                                <span class="mobile-rowexp-value"> {fakturadatadodania}</span>
                            </span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.termin_platnosci}:
                                <span class="mobile-rowexp-value"> {fakturaterminplatnosci}</span>
                            </span>
                        </div>
                    </div>`,
                        {
                            yesNo: renderBool4
                        }
                    )
                }
            },
            store: store,
            columns: columnsArray,
            frame: false,
            border: false,
            stateId: "this.grid-" + this.id,
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagetoolbarown',
                displayInfo: true,
                pagingStore: store
            }
        });
        this.grid.addListener("rowdblclick", function () {
            this.otworzRachunek();
        }, this);
    },
    refreshDatastore: function () {
        this.grid.store.load();
    },
    afterRender: function () {
        this.refreshDatastore();
        this.callParent(arguments);
    },
    otworzRachunek: function () {
        let rachunekId = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                rachunekId = this.rowContext.numerrachunku;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                rachunekId = this.grid.getSelectionModel().getSelection()[0].data.numerrachunku;
            }
        }
        if (rachunekId != 0) {
            windowObject = Ext.create('WindowOrderDetails', {numerrachunku: rachunekId})
            windowObject.showW();
            windowObject.on('closeSuccess', this.refreshData, this);
        }
    },
    refreshData: function () {
        this.grid.store.load();
    }
});
