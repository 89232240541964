Ext.define('WindowZalaczFakture', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.zalacz_fakture,
    cls: 'a-WindowZalaczFakture',
    width: 500,
    padding: 10,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    rachunek: null,
    initComponent: function () {
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            items: [{
                itemId: 'faktura-form',
                xtype: 'form',
                defaults: {
                    anchor: '0',
                    labelWidth: 80
                },
                items: [{
                    itemId: "flagazalaczeniafaktura",
                    name: "flagazalaczeniafaktura",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.faktura,
                    editable: false,
                    allowBlank: true
                }, {
                    id: this.cid + "fakturadatadodania",
                    name: "fakturadatadodania",
                    xtype: 'datefieldexdmod',
                    fieldLabel: Lang.data_dodania_faktury,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    name: "fakturaterminplatnosci",
                    id: this.cid + "fakturaterminplatnosci",
                    xtype: 'comboexd',
                    store: [0, 1, 3, 5, 7, 14, 30],
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.termin_platnosci
                }, {
                    itemId: 'zalaczona-faktura',
                    xtype: 'filefieldexd',
                    name: 'file',
                    allowBlank: false,
                    buttonText: Lang.zalacz,
                    msgTarget: 'under'
                }]
            }],
            bbar: ['->', {
                text: Lang.wyslij,
                iconCls: 'send-icon',
                iconAlign: 'right',
                listeners: {
                    scope: this,
                    click: this.wyslijFakture
                }
            }]
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.down('#flagazalaczeniafaktura').setValue(this.rachunek.flagazalaczeniafaktura ?
            Lang.zalaczono : Lang.nie_zalaczono);
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.close
            }
        });
    },
    close: function () {
        this.fireEvent('closeSuccess');
        this.callParent(arguments);
    },
    wyslijFakture: function () {
        let form = this.down('#faktura-form');
        if (form.isValid()) {
            this.mask(Lang.ladowanie);
            sendAjaxFormJsonRpcL('rest/billingHistory/uploadVatInvoice', {
                numerrachunku: this.rachunek.numerrachunku,
                fakturadatadodania: Ext.getCmp(this.cid + "fakturadatadodania").getValue2(),
                fakturaterminplatnosci: Ext.getCmp(this.cid + "fakturaterminplatnosci").getValue()
            }, form.getForm(), this, function (data) {
                if (!data.status) {
                    this.unmask();
                    Ext.MessageBox.alert(Lang.error, data.msg);
                } else {
                    this.close();
                }
            }, 0);
        }
    }
});