window.selfHereMAPLoaderObjFn = null;
window.selfHereMAPLoaderObjScp = null;
window.selfHereMAPLoaderObjLoaded = false;
window.AppMapPlatform = null;

window.loadHereMapLoader = function (ooojfn, scope) {
    if (!selfHereMAPLoaderObjLoaded) {
        selfHereMAPLoaderObjFn = ooojfn;
        selfHereMAPLoaderObjScp = scope;
        var checkkCallCallback = function () {
            Ext.Function.defer(function () {
                if (!AppMapPlatform) {
                    AppMapPlatform = new H.service.Platform({
                        'apikey': Config.heremapapikey
                    });
                }
                selfHereMAPLoaderObjLoaded = true;
                if (selfHereMAPLoaderObjFn != null) {
                    selfHereMAPLoaderObjFn.call(selfHereMAPLoaderObjScp);
                }
                selfHereMAPLoaderObjFn = null;
                selfHereMAPLoaderObjScp = null;
            }, 100, this);
        };
        var link = document.createElement('link');
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = "https://js.api.here.com/v3/3.1/mapsjs-ui.css";
        document.head.appendChild(link);
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://js.api.here.com/v3/3.1/mapsjs-core.js";
        script.charset = "utf-8";
        script.onload = function () {
            script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://js.api.here.com/v3/3.1/mapsjs-service.js";
            script.charset = "utf-8";
            script.onload = function () {
                script = document.createElement("script");
                script.type = "text/javascript";
                script.src = "https://js.api.here.com/v3/3.1/mapsjs-mapevents.js";
                script.charset = "utf-8";
                script.onload = function () {
                    script = document.createElement("script");
                    script.type = "text/javascript";
                    script.src = "https://js.api.here.com/v3/3.1/mapsjs-ui.js";
                    script.charset = "utf-8";
                    script.onload = function () {
                        checkkCallCallback();
                    }
                    document.head.appendChild(script);
                }
                document.head.appendChild(script);
            }
            document.head.appendChild(script);
        }
        document.head.appendChild(script);
    } else {
        selfHereMAPLoaderObjFn = ooojfn;
        selfHereMAPLoaderObjScp = scope;
        if (selfHereMAPLoaderObjFn != null) {
            selfHereMAPLoaderObjFn.call(selfHereMAPLoaderObjScp);
        }
        selfHereMAPLoaderObjFn = null;
        selfHereMAPLoaderObjScp = null;
    }
};

window.initializeHereMAPLoaderHandler = function () {
    selfHereMAPLoaderObjLoaded = true;
    if (selfHereMAPLoaderObjFn != null) {
        selfHereMAPLoaderObjFn.call(selfHereMAPLoaderObjScp);
    }
    selfHereMAPLoaderObjFn = null;
    selfHereMAPLoaderObjScp = null;
};
