Ext.define('WindowOperator', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.operator.capitalise(),
    width: 950,
    height: 685,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        let formpanel = {
            title: Lang.ogolne.capitalise(),
            itemId: "formpanel",
            xtype: 'form',
            frame: false,
            border: false,
            region: 'center',
            height: 550,
            scrollable: WindowExdMobileDEVICEV,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0"
            },
            items: [{
                anchor: "0",
                layout: WindowExdMobileDEVICEV ? 'fit' : {
                    type: 'table',
                    columns: 2,
                    tableAttrs: {
                        style: {
                            width: '97%'
                        }
                    },
                    tdAttrs: {
                        valign: 'top'
                    }
                },
                frame: false,
                border: false,
                items: [{
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    autoWidth: true,
                    scrollable: WindowExdMobileDEVICEV,
                    labelWidth: 80,
                    defaults: {
                        anchor: "0",
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        itemId: "imie",
                        name: "imie",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.imie,
                        allowBlank: false,
                        maxLength: 16,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        itemId: "nazwisko",
                        name: "nazwisko",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.nazwisko,
                        allowBlank: false,
                        maxLength: 16,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        itemId: 'role',
                        xtype: 'comboexd',
                        store: new Ext.data.ArrayStore({
                            data: OPERATOR_ROLES_DATA,
                            fields: ["value", "text"]
                        }),
                        displayField: "text",
                        valueField: "value",
                        triggerAction: 'all',
                        resizable: false,
                        mode: "local",
                        editable: false,
                        fieldLabel: Lang.rola,
                        listeners: {
                            scope: this
                        },
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        itemId: 'mngselector',
                        name: 'mngselector',
                        xtype: 'treepickerexd',
                        store: createTreeStore(),
                        maxPickerHeight: WindowExdMobileDEVICEV ? 200 : 300,
                        displayField: "text",
                        resizable: false,
                        editable: false,
                        fieldLabel: Lang.firma_zarzadzajaca
                    }, {
                        anchor: "0",
                        xtype: 'radiogroupexd',
                        itemId: "active",
                        name: "active",
                        baseCls: 'x-radio-column',
                        fieldLabel: Lang.active,
                        columns: 2,
                        vertical: false,
                        items: [{
                            boxLabel: Lang.tak.capitalise(),
                            inputValue: true,
                            value: true
                        }, {
                            boxLabel: Lang.nie.capitalise(),
                            inputValue: false
                        }]
                    }, {
                        xtype: 'identyfikatorpanel',
                        fieldLabel: Lang.identyfikator,
                        labelAlign: 'left',
                        id: this.cid + "identpanel"
                    }]
                }]
            }]
        };
        let loginfields = [{
            itemId: "login",
            name: "login",
            xtype: 'textfieldexd',
            fieldLabel: Lang.email,
            allowBlank: false,
            blankText: Lang.pole_nie_moze_byc_puste,
            regex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            invalidText: Lang.niepoprawny_email
        }, {
            itemId: "pass",
            name: "pass",
            xtype: 'textfieldexd',
            inputType: 'password',
            fieldLabel: Lang.haslo
        }, {
            itemId: "pass2",
            name: "pass2",
            xtype: 'textfieldexd',
            inputType: 'password',
            fieldLabel: Lang.powtorz_haslo
        }, {
            anchor: "0",
            xtype: 'radiogroupexd',
            itemId: "integratedlogin",
            name: "integratedlogin",
            baseCls: 'x-radio-column',
            fieldLabel: Lang.integrated_login,
            columns: 2,
            vertical: false,
            hidden: true,
            items: [{
                boxLabel: Lang.tak.capitalise(),
                inputValue: true
            }, {
                boxLabel: Lang.nie.capitalise(),
                inputValue: false,
                value: true
            }],
            listeners: {
                scope: this,
                change: function (el, newValue) {
                    this.down("#pass").allowBlank = newValue;
                    this.down("#pass").validate()
                    this.down("#pass2").allowBlank = newValue;
                    this.down("#pass2").validate()
                },
                scope: this
            },
        }];
        let opis = {
            anchor: "0",
            fieldLabel: Lang.opis,
            isFormField: true,
            itemId: "opis",
            name: "opis",
            xtype: 'textareaexd'
        };
        let panelstanczytnika = {
            id: this.cid + "panelstanczytnika",
            xtype: "panelstanczytnikaexd",
            hidden: WindowExdMobileDEVICEV
        };
        if (WindowExdMobileDEVICEV)
            formpanel.items[0].items[0].items = [
                ...loginfields,
                ...formpanel.items[0].items[0].items,
                panelstanczytnika,
                opis
            ];
        else {
            formpanel.items[0].items.push({
                layout: 'anchor',
                frame: false,
                border: false,
                labelWidth: 80,
                autoWidth: true,
                defaults: {
                    anchor: "0"
                },
                items: [...loginfields]
            });
            formpanel.items.push({
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                isFormField: false,
                items: [{
                    columnWidth: 0.6,
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    isFormField: false,
                    height: 150,
                    style: "margin-top:10px",
                    defaults: {
                        anchor: "0"
                    },
                    items: [opis]
                }, {
                    columnWidth: 0.4,
                    xtype: 'container',
                    layout: 'fit',
                    style: 'padding-left: 15px',
                    cls: "x-ident-main-border2",
                    height: 150,
                    id: this.cid + "identpanelct",
                    items: [panelstanczytnika]
                }]
            });
        }
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            border: false,
            deferredRender: false,
            anchor: '0',
            activeTab: 0,
            scrollable: true,
            items: [formpanel]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            itemId: 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            itemId: 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        this.obiektid = obiektid;
        this.obiekt = null;
        const mngSelector = this.down('#mngselector');
        mngSelector.setHidden(this.obiektid != 0);
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        var storeR = this.down('#role').getStore();
        var recordsToDelete = Ext.Array.filter(storeR.getData().items, function (item, index) {
            return item.data.value < AppOPERATOR.role;
        });
        storeR.remove(recordsToDelete);
        this.mask(Lang.ladowanie);
        performAjaxLPromise('rest/firmyzarzadzajace/getList').then(data => {
            const children = generateFirmaTreePanelChildren(data);
            mngSelector.setStore(createTreeStore(children));
            if (obiektid != 0) {
                this.mask(Lang.ladowanie);
                performAjaxLPromise('rest/operators/getObjectForEdit', {id: obiektid}).then(data => {
                    this.obiekt = data;
                    this.down('#save').setDisabled(AppOPERATOR.role > this.obiekt.role)
                    this.down('#imie').setValue(this.obiekt.imie);
                    this.down('#nazwisko').setValue(this.obiekt.nazwisko);
                    this.down('#login').setValue(this.obiekt.login);
                    this.down('#role').setValue(this.obiekt.role);
                    this.down('#active').setValue(this.obiekt.active);
                    this.down('#opis').setValue(this.obiekt.opis);
                    this.down('#integratedlogin').setValue(this.obiekt.integratedlogin);
                    this.identPanel = Ext.getCmp(this.cid + "identpanel");
                    this.identPanel.init({
                        obiektid: obiektid,
                        objectt: "operator",
                        object: this.obiekt,
                        sysidentparams: {
                            "ident_tryb": Const.IDENT_ELEKTRONICZNAPIN,
                            "ident_operator": Const.IDENT_ELEKTRONICZNAPIN
                        },
                        readerPanel: Ext.getCmp(this.cid + 'panelstanczytnika')
                    });
                }).catch(error => {
                    handleCommonCallBackAjaxRPCError(error);
                }).finally(() => {
                    this.unmask();
                });
            } else {
                this.identPanel = Ext.getCmp(this.cid + "identpanel");
                this.identPanel.init({
                    obiektid: obiektid,
                    objectt: "operator",
                    sysidentparams: {
                        "ident_tryb": Const.IDENT_ELEKTRONICZNAPIN,
                        "ident_operator": Const.IDENT_ELEKTRONICZNAPIN
                    },
                    readerPanel: Ext.getCmp(this.cid + 'panelstanczytnika')
                });
            }
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        }).finally(() => {
            this.unmask();
        });
    },
    saveOp: function () {
        var canWrite = this.down("#formpanel").isValid();
        if (!this.down('#integratedlogin').getValue2()) {
            if (this.down("#pass").getValue().trim() != "") {
                if (this.down("#pass").getValue() != this.down("#pass2").getValue()) {
                    Ext.MessageBox.alert(Lang.error, Lang.puste_haslo_lub_bledna_weryfikacja);
                    this.down("#pass").markInvalid(Lang.puste_haslo_lub_bledna_weryfikacja);
                    this.down("#pass2").markInvalid(Lang.puste_haslo_lub_bledna_weryfikacja);
                    return;
                }
            } else if (this.obiekt == null) {
                Ext.MessageBox.alert(Lang.error, Lang.puste_haslo_lub_bledna_weryfikacja);
                this.down("#pass").markInvalid(Lang.puste_haslo_lub_bledna_weryfikacja);
                this.down("#pass2").markInvalid(Lang.puste_haslo_lub_bledna_weryfikacja);
                return;
            }
        }
        if (this.down("#role").getSelection() == null) {
            this.down("#role").markInvalid(Lang.pole_nie_moze_byc_puste);
            return;
        }
        var vii = this.identPanel.getIdentData();
        if (vii && vii.identyfikator == "")
            vii.identyfikator = null;
        var login = this.down("#login").getValue().trim();
        if (canWrite) {
            this.mask(Lang.zapisywanie);
            let newOperator = {
                id: this.obiektid,
                imie: this.down("#imie").getValue(),
                nazwisko: this.down("#nazwisko").getValue(),
                login: login,
                role: this.down("#role").getValue(),
                active: this.down('#active').getValue(),
                opis: this.down("#opis").getValue(),
                integratedlogin: this.down("#integratedlogin").getValue2(),
                identyfikator: vii.identyfikator,
                identtype: vii.identtype,
                pin: vii.pin,
                pass: this.down("#pass").getValue().trim() == '' ? null : this.down("#pass").getValue().trim()
            };
            let endpoint;
            if (this.obiektid == 0) {
                endpoint = 'createObject';
                newOperator.firmaZarzadzajacaId = this.down('#mngselector').getValue();
            } else {
                endpoint = 'saveObject';
            }
            performAjaxLPromise(`rest/operators/${endpoint}`, newOperator).then(() => {
                this.fireEvent('closeSuccess');
                this.close();
            }).catch((error) => {
                if (error == Lang.identyfikator_uzywany) {
                    this.identPanel.markIdAsUsed();
                }
                this.unmask();
                handleCommonCallBackAjaxRPCError(error);
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
