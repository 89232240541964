Ext.define('WindowManagingCompany', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.firma_zarzadzajaca.capitalise(),
    width: 800,
    scrollable: true,
    closable: true,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    obiektid: null,
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            itemId: 'company-form',
            xtype: 'form',
            layout: 'anchor',
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0",
                labelWidth: 150,
                labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
            },
            items: [{
                itemId: 'nazwa',
                xtype: 'textfieldexd',
                fieldLabel: Lang.nazwa,
                allowBlank: false,
                maxLength: 200,
                blankText: Lang.pole_nie_moze_byc_puste
            }, {
                itemId: 'telefon',
                xtype: 'textfieldexd',
                fieldLabel: Lang.telefon,
                allowBlank: false,
                maxLength: 64,
                blankText: Lang.pole_nie_moze_byc_puste
            }, {
                itemId: 'email',
                xtype: 'textfieldexd',
                fieldLabel: Lang.email,
                regex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                allowBlank: false,
                maxLength: 128,
                blankText: Lang.pole_nie_moze_byc_puste,
                invalidText: Lang.niepoprawny_email
            }, {
                itemId: 'mngselector',
                xtype: 'treepickerexd',
                store: createTreeStore(),
                maxPickerHeight: WindowExdMobileDEVICEV ? 200 : 300,
                fieldLabel: Lang.firma_zarzadzajaca,
                editable: false,
                allowBlank: false,
                blankText: Lang.pole_nie_moze_byc_puste,
                displayField: "text"
            }, {
                itemId: 'customModFlag',
                name: 'customModFlag',
                xtype: 'comboexd',
                labelWidth: 150,
                store: CUSTOM_MOD_FLAG_DATA,
                fieldLabel: Lang.modyfikacja_licencji,
                displayField: 'text',
                valueField: 'text',
                triggerAction: 'all',
                resizable: false,
                mode: 'local',
                editable: false,
                allowBlank: false,
                hidden: AppOPERATORMNGFIRMAID != Const.FIRMA_ZARZADZAJACA_ID1,
                value: AppOPERATORMNGFIRMAID == Const.FIRMA_ZARZADZAJACA_ID1 ? null : 'default',
                blankText: Lang.pole_nie_moze_byc_puste
            }, {
                itemId: 'tplident',
                xtype: 'textfieldexd',
                fieldLabel: Lang.tpl_identyfikator,
                allowBlank: true
            }, {
                anchor: "0",
                fieldLabel: Lang.opis,
                isFormField: true,
                itemId: "opis",
                name: "opis",
                xtype: 'textareaexd'
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        this.obiektid = obiektid;
        this.obiekt = null;
        const mngSelector = this.down('#mngselector');
        this.show();
        this.mask(Lang.ladowanie);
        performAjaxLPromise('rest/firmyzarzadzajace/getList').then(data => {
            const managingCompanies = generateFirmaTreePanelChildren(data);
            mngSelector.setStore(createTreeStore(managingCompanies));
            if (obiektid != 0) {
                performAjaxLPromise('rest/firmyzarzadzajace/getObjectForEdit', {
                    id: obiektid
                }).then(data => {
                    this.obiekt = data;
                    this.down('#nazwa').setValue(this.obiekt.nazwa);
                    this.down('#telefon').setValue(this.obiekt.telefon);
                    this.down('#email').setValue(this.obiekt.email);
                    this.down('#opis').setValue(this.obiekt.opis);
                    this.down('#customModFlag').setValue(this.obiekt.custommodflag == null ? 'default' : this.obiekt.custommodflag);
                    this.down('#tplident').setValue(this.obiekt.tplident);
                    mngSelector.setValue(this.obiekt.idparent);
                    mngSelector.getPicker().expandPath(`${this.obiekt.idparent}`);
                    mngSelector.setDisabled(obiektid == 1);
                });
            }
        }).catch(err => {
            handleCommonCallBackAjaxRPCError(err);
        }).finally(() => {
            this.unmask();
        });
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
    },
    saveOp: function () {
        let canWrite = this.down('#company-form').isValid();
        if (canWrite) {
            this.mask(Lang.ladowanie);
            const custommodflag = this.down('#customModFlag').getValue();
            let newCompany = {
                nazwa: this.down('#nazwa').getValue(),
                telefon: this.down('#telefon').getValue(),
                tplident: this.down('#tplident').getValue(),
                email: this.down('#email').getValue(),
                idparent: this.down('#mngselector').getValue(),
                opis: this.down('#opis').getValue(),
                custommodflag: custommodflag == 'default' ? null : custommodflag
            };
            let endPoint;
            if (this.obiektid == 0) {
                endPoint = 'createObject';
            } else {
                endPoint = 'saveObject';
                newCompany.id = this.obiektid;
            }
            performAjaxLPromise(`rest/firmyzarzadzajace/${endPoint}`, newCompany).then(() => {
                this.closeOp();
            }).catch(err => {
                this.unmask();
                handleCommonCallBackAjaxRPCError(err);
            });
        }
    },
    closeOp: function () {
        this.fireEvent('closeSuccess', {
            expandPathId: this.obiekt && this.obiekt.idparent ? this.obiekt.idparent : null
        });
        this.close();
    }
});
