Ext.define('WindowRachunki', {
    extend: 'Ext.ux.window.WindowExd',
    cls: 'a-WindowRachunki',
    title: Lang.rachunki,
    width: 1300,
    padding: 10,
    collapsible: false,
    firma: null,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    grid: null,
    windowObject: null,
    initComponent: function () {
        this.buildGrid(false);
        this.items = [{
            xtype: 'panel',
            layout: {
                type: 'vbox',
                align: 'stretch'
            },
            items: [{
                xtype: 'toolbar',
                style: 'padding: 5px;',
                items: [{
                    iconCls: 'receipt-icon',
                    xtype: 'button',
                    text: Lang.podglad,
                    scope: this,
                    handler: this.otworzRachunek
                }]
            }, {
                xtype: 'panel',
                layout: 'fit',
                flex: 1,
                border: false,
                items: [this.grid]
            }]
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.close
            }
        });
    },
    buildGrid: function () {
        var cols = [{
            header: Lang.numer_rachunku,
            flex: 1,
            sortable: true,
            dataIndex: 'numerrachunku',
            filter: {
                type: 'numeric'
            }
        }, {
            header: Lang.data_utworzenia,
            flex: 2,
            sortable: true,
            dataIndex: 'datautworzenia',
            filter: {
                type: 'date'
            }
        }, {
            header: Lang.oplacono,
            flex: 1,
            sortable: true,
            dataIndex: 'flagaoplacenia',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            }
        }, {
            header: Lang.zatwierdzono,
            flex: 1,
            sortable: true,
            dataIndex: 'flagazatwierdzenia',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            }
        }, {
            header: Lang.faktura_zalaczona,
            flex: 2,
            sortable: true,
            dataIndex: 'flagazalaczeniafaktury',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.faktura_wyslana,
            flex: 2,
            sortable: true,
            dataIndex: 'flagawyslaniafaktura',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.proforma_zalaczona,
            flex: 2,
            sortable: true,
            dataIndex: 'flagazalaczeniaproforma',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.proforma_wyslana,
            flex: 2,
            sortable: true,
            dataIndex: 'flagawyslaniaproforma',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.data_dodania_faktury,
            flex: 2,
            sortable: true,
            dataIndex: 'fakturadatadodania',
            filter: {
                type: 'date'
            }
        }, {
            header: Lang.termin_platnosci,
            flex: 1,
            sortable: true,
            dataIndex: 'fakturaterminplatnosci',
            filter: {
                type: 'numeric'
            },
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.operator,
            flex: 2,
            sortable: true,
            dataIndex: 'userimienazwisko',
            filter: {
                type: 'string'
            },
            filterable: true
        }];
        let store = new Ext.data.Store({
            remoteSort: true,
            remoteFilter: true,
            autoLoad: false,
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: "numerrachunku",
                fields: [{
                    name: 'numerrachunku',
                    mapping: 'numerrachunku'
                }, {
                    name: 'datarozpoczecia',
                    mapping: 'datarozpoczecia'
                }, {
                    name: 'datazakonczenia',
                    mapping: 'datazakonczenia'
                }, {
                    name: 'flagaoplacenia',
                    mapping: 'flagaoplacenia'
                }, {
                    name: 'flagauniewznienia',
                    mapping: 'flagauniewznienia'
                }, {
                    name: 'flagazalaczeniafaktury',
                    mapping: 'flagazalaczeniafaktury'
                }, {
                    name: 'flagawyslaniafaktura',
                    mapping: 'flagawyslaniafaktura'
                }, {
                    name: 'flagazalaczeniaproforma',
                    mapping: 'flagazalaczeniaproforma'
                }, {
                    name: 'flagawyslaniaproforma',
                    mapping: 'flagawyslaniaproforma'
                }, {
                    name: 'fakturadatadodania',
                    mapping: 'fakturadatadodania'
                }, {
                    name: 'fakturaterminplatnosci',
                    mapping: 'fakturaterminplatnosci'
                }, {
                    name: 'flagazatwierdzenia',
                    mapping: 'flagazatwierdzenia'
                }, {
                    name: 'userimienazwisko',
                    mapping: 'userimienazwisko'
                }]
            }),
            sorters: [{
                property: "numerrachunku",
                direction: "DESC"
            }],
            proxy: {
                type: 'ajax',
                url: 'rest/billingHistory/getList',
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'numerrachunku'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true,
                rowexpander: !WindowExdMobileDEVICEV ? null : {
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.numer_rachunku}:
                                <span class="mobile-rowexp-value"> {numerrachunku}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.data_rozpoczecia}:
                                <span class="mobile-rowexp-value"> {datarozpoczecia}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.data_zakonczenia}:
                                <span class="mobile-rowexp-value"> {datazakonczenia}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.data_utworzenia}:
                                <span class="mobile-rowexp-value"> {datautworzenia}</span>
                            </span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.oplacono}:</span>
                            <span class="mobile-rowexp-value"> {flagaoplacenia:this.yesNo}</span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.zatwierdzono}:</span>
                            <span class="mobile-rowexp-value"> {flagazatwierdzenia:this.yesNo}</span>
                        </div>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.faktura_zalaczona}:</span>
                            <span class="mobile-rowexp-value"> {flagazalaczeniafaktury:this.yesNo}</span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.faktura_wyslana}:</span>
                            <span class="mobile-rowexp-value"> {flagawyslaniafaktura:this.yesNo}</span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.proforma_zalaczona}:</span>
                            <span class="mobile-rowexp-value"> {flagazalaczeniaproforma:this.yesNo}</span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.proforma_wyslana}:</span>
                            <span class="mobile-rowexp-value"> {flagawyslaniaproforma:this.yesNo}</span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.data_dodania_faktury}:
                                <span class="mobile-rowexp-value"> {fakturadatadodania}</span>
                            </span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.termin_platnosci}:
                                <span class="mobile-rowexp-value"> {fakturaterminplatnosci}</span>
                            </span>
                        </div>
                    </div>`,
                        {
                            yesNo: renderBool4
                        }
                    )
                }
            },
            height: WindowExdMobileDEVICEV ? '100%' : 500,
            store: store,
            columns: cols,
            frame: false,
            border: false,
            stateful: true,
            stripeRows: true,
            forceFit: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: WindowExdMobileDEVICEV ? 'pagetoolbarown' : 'pagingtoolbarexd',
                displayInfo: true,
                pagingStore: store
            }
        });
        this.grid.addListener("rowdblclick", function () {
            this.otworzRachunek();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            id: this.id + "botworz",
            iconAlign: 'left',
            iconCls: "iconEdit",
            text: Lang.otworz,
            scope: this,
            handler: this.otworzRachunek
        }]);
        this.grid.addListener("rowcontextmenu", function (grid, record, tr, rowIndex, e) {
            this.rowContext = grid.getStore().getAt(rowIndex);
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.taskContextMenu.addListener("hide", function () {
            this.rowContext = null;
        }, this);
        this.grid.addListener("containercontextmenu", function (grid, e) {
            this.rowContext = null;
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.refreshData();
    },
    manageSelect: function () {
        if (this.showState) {
            var botworz = Ext.getCmp(this.id + "botworz");
            if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
            } else {
                var row = this.grid.getSelectionModel().getSelection()[0];
            }
            if (this.rowContext == null) {
                botworz.setDisabled(true);
            } else {
                botworz.setDisabled(false);
            }
        }
    },
    otworzRachunek: function () {
        let rachunekId = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                rachunekId = this.rowContext.numerrachunku;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                rachunekId = this.grid.getSelectionModel().getSelection()[0].data.numerrachunku;
            }
        }
        if (rachunekId != 0) {
            windowObject = Ext.create('WindowOrderDetails', {numerrachunku: rachunekId})
            windowObject.showW();
            windowObject.on('closeSuccess', this.refreshData, this);
        }
    },
    refreshData: function () {
        this.grid.store.load();
    },
    close: function () {
        this.fireEvent('closeSuccess');
        this.callParent(arguments);
    }
});