Ext.define('ViewOperators', {
    extend: 'Ext.panel.Panel',
    grid: null,
    windowObject: null,
    id: "operatorzy",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyName: "access.manage.coperatorzy",
        accessPropertyViewName: "access.view.coperatorzy"
    },
    cat: "p",
    text: Lang.operatorzy,
    buttonIconCls: "iconOperator",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                text: Lang.usun.capitalise(),
                scope: this,
                handler: this.usunObiekt
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: 'iconFirma',
                text: Lang.firmy_zarzadzajace,
                scope: this,
                handler: this.showManagingCompanies,
                disabled: !validPrivilegesOperatorComplex(WindowManageManagingCompanies.accessPropertyViewName)
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    scrollToView: function (id) {
        this.grid.selectRowScroll("id", id);
    },
    buildGrid: function () {
        var cols = [{
            header: "ID",
            width: 145,
            sortable: true,
            dataIndex: 'id',
            filter: {
                type: 'numeric'
            },
            hidden: true
        }, {
            header: Lang.imie,
            flex: 2,
            sortable: true,
            dataIndex: 'imie',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.nazwisko,
            flex: 2,
            sortable: true,
            dataIndex: 'nazwisko',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.login,
            flex: 2,
            sortable: true,
            dataIndex: 'login',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.email,
            flex: 2,
            sortable: true,
            dataIndex: 'email',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.rola,
            flex: 2,
            sortable: true,
            dataIndex: 'role',
            filter: {
                type: 'numeric'
            },
            renderer: function (value) {
                return OPERATOR_ROLES_DATA.find(role => role[0] == value)[1]
            }
        }, {
            header: Lang.firma_zarzadzajaca,
            flex: 2,
            sortable: true,
            dataIndex: 'firmaZarzadzajaca',
            filter: {
                type: 'string'
            }
        }, {
            header: Ext.DomHelper.markup({
                tag: "div",
                cls: "iconDivCol iconActive",
                cn: [{
                    tag: "span",
                    html: "&nbsp;"
                }, {
                    tag: "div",
                    html: Lang.active
                }]
            }),
            menuText: Lang.active,
            flex: 1,
            sortable: true,
            dataIndex: 'active',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            }
        }];
        let store = new Ext.data.Store({
            remoteSort: true,
            remoteFilter: true,
            autoLoad: false,
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: "id",
                fields: [{
                    name: 'id',
                    mapping: 'id'
                }, {
                    name: 'imie',
                    mapping: 'imie'
                }, {
                    name: 'nazwisko',
                    mapping: 'nazwisko'
                }, {
                    name: 'active',
                    mapping: 'active'
                }, {
                    name: 'login',
                    mapping: 'login'
                }, {
                    name: 'opis',
                    mapping: 'opis'
                }, "firmaZarzadzajaca"]
            }),
            sorters: [{
                property: "imie",
                direction: "ASC"
            }],
            proxy: {
                type: 'ajax',
                url: 'rest/operators/getList',
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'id'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true
            },
            store: store,
            columns: cols,
            frame: false,
            border: false,
            stateId: "grid-" + this.id + "-all",
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagingtoolbarexd',
                displayInfo: true,
                pagingStore: store
            }
        });
        this.grid.addListener("rowdblclick", function () {
            this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            iconAlign: 'left',
            iconCls: "iconNew",
            text: Lang.nowy.capitalise(),
            scope: this,
            handler: this.dodajObiekt
        }, {
            id: this.id + "bedit2",
            iconAlign: 'left',
            iconCls: "iconEdit",
            text: Lang.edytuj.capitalise(),
            scope: this,
            handler: this.edytujObiekt
        }, {
            id: this.id + "bdelete2",
            iconAlign: 'left',
            iconCls: "iconDelete",
            text: Lang.usun.capitalise(),
            scope: this,
            handler: this.usunObiekt
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconRefresh",
            text: Lang.odswiez.capitalise(),
            scope: this,
            handler: this.refreshDatastore
        }]);
        this.grid.addListener("rowcontextmenu", function (grid, record, tr, rowIndex, e) {
            this.rowContext = grid.getStore().getAt(rowIndex);
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.taskContextMenu.addListener("hide", function () {
            this.rowContext = null;
        }, this);
        this.grid.addListener("containercontextmenu", function (grid, e) {
            this.rowContext = null;
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
    },
    manageSelect: function () {
        if (this.showState) {
            var bedit = Ext.getCmp(this.id + "bedit");
            var bdelete = Ext.getCmp(this.id + "bdelete");
            var bedit2 = Ext.getCmp(this.id + "bedit2");
            var bdelete2 = Ext.getCmp(this.id + "bdelete2");
            if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
                bdelete.setDisabled(true);
                bedit.setDisabled(true);
            } else {
                var row = this.grid.getSelectionModel().getSelection()[0];
                bdelete.setDisabled(false);
                bedit.setDisabled(false);
            }
            if (this.rowContext == null) {
                bdelete2.setDisabled(true);
                bedit2.setDisabled(true);
            } else {
                bdelete2.setDisabled(false);
                bedit2.setDisabled(false);
            }
        }
    },
    refreshDatastore: function () {
        this.grid.store.load();
    },
    dodajObiekt: function () {
        var windowObject = Ext.create('WindowOperator');
        windowObject.showW(0);
        windowObject.on('closeSuccess', this.refreshDatastore, this);
    },
    edytujObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length == 1) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        var windowObject = Ext.create('WindowOperator');
        windowObject.showW(id);
        windowObject.on('closeSuccess', this.refreshDatastore, this);
    },
    usunObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            msg: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/operators/deleteObject", {
                        id: id
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    showManagingCompanies: function () {
        let windowObject = Ext.create('WindowManageManagingCompanies');
        windowObject.on('closeSuccess', this.refreshDatastore);
        windowObject.showW();
    }
});