function User() {
    window.WindowExdMobileDEVICEV = Ext.os.is.Phone && (Config.mobileuidisabled != 1);
    var UseMobileUI = Ext.os.is.Phone && (Config.mobileuidisabled != 1);

    function initMain() {
        function getViewButton(id, text, iconCls, handler, c) {
            return {
                cat: c,
                text: text,
                xtype: 'button',
                iconCls: iconCls,
                iconAlign: 'top',
                scale: 'large',
                enableToggle: false,
                id: "main-view-button-" + id,
                cls: 'main-view',
                focusable: false,
                handler: handler
            };
        }

        function getViewPanelTab(o) {
            var panelHi = Ext.id();
            Ext.each(o.buttons, function (e) {
                e.panelHi = panelHi;
            });
            var panelH = Ext.create('Ext.panel.Panel', {
                id: panelHi,
                border: false,
                collapsible: true,
                scrollable: true,
                hideCollapseTool: true,
                bodyCls: "x-main-panel-buttons",
                layout: {
                    type: 'vbox',
                    align: 'stretch'
                },
                title: o.title,
                items: o.buttons,
                listeners: {
                    afterrender: function (c) {
                        var tcc = c.body.child(".x-table-layout");
                        if (tcc != null) {
                            var tccH = tcc.getHeight();
                            var cbodyH = c.body.getHeight();
                            var lccF = tcc.getHeight() > c.body.getHeight();
                            Ext.each(o.buttons, function (v) {
                                var btn = Ext.getCmp(v.id);
                                btn.setWidth('100%');
                            });
                        }
                    },
                    resize: function (c, w, h, rw, rh) {
                        var tcc = c.body.child(".x-table-layout");
                        if (tcc != null) {
                            var tccH = tcc.getHeight();
                            var cbodyH = c.body.getHeight();
                            var lccF = tcc.getHeight() > c.body.getHeight();
                            Ext.each(o.buttons, function (v) {
                                var btn = Ext.getCmp(v.id);
                                btn.setWidth('100%');
                            });
                        }
                    }
                }
            });
            return panelH;
        }

        var views = [];
        if (validPrivilegesOperatorComplex(ViewDashboard.accessPropertyViewName))
            views.push(Ext.create('ViewDashboard'));
        if (validPrivilegesOperatorComplex(ViewOperators.accessPropertyViewName) && !isValidOperatorFirmaRaw())
            views.push(Ext.create('ViewOperators'));
        if (validPrivilegesOperatorComplex(ViewFirmas.accessPropertyViewName) && !isValidOperatorFirmaRaw())
            views.push(Ext.create('ViewFirmas'));
        if (validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyViewName) && !isValidOperatorFirmaRaw())
            views.push(Ext.create('ViewSterowniki'));
        if (validPrivilegesOperatorComplex(ViewRachunki.accessPropertyViewName) && !isValidOperatorFirmaRaw())
            views.push(Ext.create('ViewRachunki'));
        var viewsP = [];
        var viewsA = {};
        var viewClasses = {};
        for (var i = 0; i < views.length; i++) {
            var button = {
                xtype: 'button',
                iconAlign: 'top',
                scale: 'large',
                enableToggle: true,
                cls: 'main-view',
                id: views[i].buttonId,
                cat: views[i].cat,
                text: views[i].text,
                iconCls: views[i].buttonIconCls,
                panelView: views[i],
                focusable: false,
                blocked: false
            };
            button.handler = function () {
                if (!this.blocked) {
                    Ext.suspendLayouts();
                    var contenerViews = Ext.getCmp("main-view-contenerviews");
                    if (!this.pressed)
                        this.toggle(true, true);
                    if (AppCommonData.CurrentView != null) {
                        if (AppCommonData.CurrentView.id == this.id)
                            return;
                        else {
                            AppCommonData.CurrentView.toggle(false, true);
                            AppCommonData.CurrentView.panelView.hide();
                            AppCommonData.CurrentView.blocked = false;
                        }
                    }
                    var button = this;
                    this.blocked = true;
                    if (contenerViews.getComponent(button.panelView.id) == null) {
                        if (button.panelView.destroyed) {
                            button.panelView = Ext.create(viewClasses[button.panelView.id]);
                        }
                        contenerViews.add(button.panelView);
                    }
                    contenerViews.getLayout().setActiveItem(button.panelView.id);
                    AppCommonData.CurrentView = button;
                    var tttxxcc = button.text.replace(/&nbsp;/g, "");
                    var textC = tttxxcc.capitalise();
                    Ext.get("main-panel-title-north").update(textC);
                    updateGUILocationHash({
                        "uvroute": AppCommonData.CurrentView.panelView.id
                    });
                    Ext.resumeLayouts(true);
                    button.panelView.show();
                } else {
                    if (!this.pressed) {
                        this.toggle(true, true);
                    }
                }
            };
            viewClasses[button.panelView.id] = Ext.getClassName(button.panelView);
            viewsA[button.panelView.id] = button;
            viewsP.push(button);
        }
        if (validPrivilegesOperatorComplex("access.view.caggregatedcore") && AppOPERATORFIRMA == null) {
            viewsP.push(getViewButton("aggregated-panel", Lang.zbiorczy_panel_uzytkownika, "iconAggregatedPanel", function () {
                performAjaxLPromise('rest/systemreq/updateContextSetHighestFirm').then(() => {
                    window.location = Config.prefixportalcorelink + '?aggregated=true';
                }).catch(error => {
                    handleCommonCallBackAjaxRPCError(error);
                });
            }, "p"));
        }
        viewsP.push(getViewButton("opc-konf", Lang.zmien_obecnego_operatora, "iconKonfOPC", function () {
            var w = Ext.create('WindowOperatorCurrentChange');
            w.showW();
        }, "p"));
        viewsP.push(getViewButton("about", Lang.o_programie, "iconAbout", function () {
            var w = Ext.create('WindowAbout');
            w.showW();
        }, "p"));
        var ttls = {
            p: Lang.podglad
        };
        var viewsPP = {};
        Ext.each(viewsP, function (e) {
            if (!viewsPP[e.cat]) {
                viewsPP[e.cat] = {};
                viewsPP[e.cat].title = ttls[e.cat].capitalise();
                viewsPP[e.cat].buttons = [];
            }
            e.text = e.text.capitalise();
            if (e.text.length < 18) {
                var ll = 18 - e.text.length;
                ll = ll / 2;
                var ty = "";
                for (var i = 0; i < ll; i++)
                    ty += "&nbsp;";
                e.text = ty + e.text + ty;
            }
            viewsPP[e.cat].buttons.push(e);
        });
        var viewsPPP = [];
        for (var a in viewsPP) {
            viewsPPP.push(getViewPanelTab(viewsPP[a]));
        }
        var viewClsImageTitle = "main-panel-image-title4cloud";
        var viewPort = Ext.create('Ext.container.Viewport', {
            minHeight: 500,
            minWidth: 1024,
            layout: 'border',
            border: false,
            items: [{
                id: "main-view-header",
                xtype: 'panel',
                region: 'north',
                bodyCls: "cn-panel main-panel-image-title3",
                height: 50,
                border: false,
                html: {
                    cls: "cn-panel",
                    cn: [{
                        cls: viewClsImageTitle
                    }, {
                        cls: "main-panel-image-title5"
                    }, {
                        cls: "main-panel-button-header-help",
                        id: "main-panel-button-header-help"
                    }, {
                        cls: "main-panel-button-header-logout",
                        id: "main-panel-button-header-logout"
                    }]
                }
            }, {
                border: false,
                region: 'center',
                xtype: 'panel',
                layout: 'border',
                cls: Ext.os.deviceType != "Desktop" && Ext.os.name != "iOS" && Ext.browser.is("Chrome") ? "mobile-scaling" : null,
                items: [{
                    border: true,
                    xtype: 'panel',
                    region: 'west',
                    multi: false,
                    bodyStyle: "border-top:0px",
                    width: 200,
                    collapsible: true,
                    collapsed: Ext.os.deviceType != "Desktop",
                    titleCollapse: true,
                    layout: {
                        type: 'accordion',
                        titleCollapse: true,
                        sequence: false,
                        animate: false
                    },
                    cls: "main-view-accordion",
                    bodyStyle: 'background-color:#F1F1F1',
                    items: viewsPPP
                }, {
                    xtype: 'panel',
                    border: true,
                    bodyStyle: "border-top:0px;border-right:0px",
                    region: 'center',
                    layout: 'border',
                    items: [{
                        region: 'north',
                        border: false,
                        id: "main-view-northviews",
                        bodyCls: "main-panel-image-title1",
                        xtype: 'panel',
                        layout: 'fit',
                        height: 25,
                        html: {
                            cls: "cn-panel",
                            cn: [{
                                cls: "main-panel-image-title6"
                            }, {
                                cls: "main-panel-title-north",
                                id: "main-panel-title-north"
                            }]
                        }
                    }, {
                        border: true,
                        id: "main-view-contenerviews",
                        xtype: 'panel',
                        style: 'width: 100%;',
                        region: 'center',
                        layout: 'card',
                        layoutConfig: {
                            deferredRender: true
                        },
                        activeItem: 0,
                        items: []
                    }]
                }]
            }, {
                id: "main-view-statusbar",
                xtype: 'toolbar',
                region: 'south',
                height: 35,
                items: [{
                    id: "updateloginuser",
                    xtype: 'label',
                    style: 'display:block;margin-left:5px'
                }, "->", {
                    id: "totalinfo",
                    xtype: 'label',
                    style: 'display:block;margin-right:5px'
                }]
            }]
        });
        Ext.get("main-panel-button-header-help").setText(Lang.header_help);
        Ext.get("main-panel-button-header-help").on("click", function () {
            Ext.create('WindowHelp', {src: "https://staticcdn.smdp.online/help/help_arccan.html"}).showW();
        });
        Ext.get("main-panel-button-header-logout").setText(Lang.header_loguout);
        Ext.get("main-panel-button-header-logout").on("click", function () {
            window.location = "rest/systemreq/userSignOut";
        });
        Ext.defer(() => {
            var updateloginuser = Ext.getCmp("updateloginuser");
            updateloginuser.setText(AppOPERATOR.imie + " " + AppOPERATOR.nazwisko);
            var viewc = AppCLocationParams.uvroute;
            if (!viewc) {
                if (viewsA["dashboard"])
                    viewc = Ext.getCmp(viewsA["dashboard"].id);
                if (viewc) {
                    viewc.handler.call(viewc);
                }
            } else {
                viewc = Ext.getCmp(viewsA[viewc].id);
                viewc.handler.call(viewc);
                if (Ext.os.deviceType != "Desktop") {
                    var accordion = Ext.getCmp(viewc.panelHi).up();
                    accordion.on("expand", function () {
                        Ext.getCmp(viewc.panelHi).expand(2000);
                    }, null, {
                        single: true
                    });
                } else {
                    Ext.getCmp(viewc.panelHi).expand();
                }
            }
        }, 250);
    }

    function initMainMobile() {
        function getViewButton(id, text, iconCls, handler, c) {
            return {
                cat: c,
                text: text,
                xtype: 'button',
                iconCls: iconCls,
                iconAlign: 'top',
                scale: 'large',
                enableToggle: false,
                id: "main-view-button-" + id,
                cls: 'main-view',
                focusable: false,
                handler: handler
            };
        }

        function getViewPanelTab(o) {
            var panelHi = Ext.id();
            Ext.each(o.buttons, function (e) {
                e.panelHi = panelHi;
            });
            var panelH = Ext.create('Ext.panel.Panel', {
                id: panelHi,
                border: false,
                collapsible: true,
                scrollable: true,
                hideCollapseTool: true,
                bodyCls: "x-main-panel-buttons",
                layout: {
                    type: 'vbox',
                    align: 'stretch'
                },
                plugins: {
                    responsive: true
                },
                title: o.title,
                items: o.buttons,
                listeners: {
                    afterrender: function (c) {
                        var tcc = c.body.child(".x-table-layout");
                        if (tcc != null) {
                            var tccH = tcc.getHeight();
                            var cbodyH = c.body.getHeight();
                            var lccF = tcc.getHeight() > c.body.getHeight();
                            Ext.each(o.buttons, function (v) {
                                var btn = Ext.getCmp(v.id);
                                btn.setWidth('100%');
                            });
                        }
                    },
                    resize: function (c, w, h, rw, rh) {
                        var tcc = c.body.child(".x-table-layout");
                        if (tcc != null) {
                            var tccH = tcc.getHeight();
                            var cbodyH = c.body.getHeight();
                            var lccF = tcc.getHeight() > c.body.getHeight();
                            Ext.each(o.buttons, function (v) {
                                var btn = Ext.getCmp(v.id);
                                btn.setWidth('100%');
                            });
                        }
                    }
                }
            });
            return panelH;
        }

        var views = [];
        if (validPrivilegesOperatorComplex(ViewDashboard.accessPropertyViewName))
            views.push(Ext.create('ViewDashboard'));
        if (validPrivilegesOperatorComplex(ViewOperators.accessPropertyViewName) && !isValidOperatorFirmaRaw())
            views.push(Ext.create('ViewOperatorsMobile'));
        if (validPrivilegesOperatorComplex(ViewFirmas.accessPropertyViewName) && !isValidOperatorFirmaRaw())
            views.push(Ext.create('ViewFirmasMobile'));
        if (validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyViewName) && !isValidOperatorFirmaRaw())
            views.push(Ext.create('ViewSterownikiMobile'));
        if (validPrivilegesOperatorComplex(ViewRachunki.accessPropertyViewName) && !isValidOperatorFirmaRaw())
            views.push(Ext.create('ViewRachunkiMobile'));
        var viewsP = [];
        var viewsA = {};
        var viewClasses = {};
        for (var i = 0; i < views.length; i++) {
            var button = {
                xtype: 'button',
                iconAlign: 'top',
                scale: 'large',
                enableToggle: true,
                cls: 'main-view',
                id: views[i].buttonId,
                cat: views[i].cat,
                text: views[i].text,
                iconCls: views[i].buttonIconCls,
                panelView: views[i],
                focusable: false,
                blocked: false
            };
            button.handler = function () {
                if (!this.blocked) {
                    Ext.suspendLayouts();
                    var contenerViews = Ext.getCmp("main-view-contenerviews");
                    if (!this.pressed)
                        this.toggle(true, true);
                    if (AppCommonData.CurrentView != null) {
                        if (AppCommonData.CurrentView.id == this.id)
                            return;
                        else {
                            AppCommonData.CurrentView.toggle(false, true);
                            AppCommonData.CurrentView.panelView.hide();
                            AppCommonData.CurrentView.blocked = false;
                        }
                    }
                    var button = this;
                    this.blocked = true;
                    if (contenerViews.getComponent(button.panelView.id) == null) {
                        if (button.panelView.destroyed) {
                            button.panelView = Ext.create(viewClasses[button.panelView.id]);
                        }
                        contenerViews.add(button.panelView);
                    }
                    contenerViews.getLayout().setActiveItem(button.panelView.id);
                    AppCommonData.CurrentView = button;
                    var tttxxcc = button.text.replace(/&nbsp;/g, "");
                    var textC = tttxxcc.capitalise();
                    Ext.get("main-panel-title-north").update(textC);
                    updateGUILocationHash({
                        "uvroute": AppCommonData.CurrentView.panelView.id
                    });
                    Ext.resumeLayouts(true);
                    button.panelView.show();
                    if (Ext.getCmp('main-view-sidebar-menu'))
                        Ext.getCmp('main-view-sidebar-menu').setHidden(true);
                } else {
                    if (!this.pressed) {
                        this.toggle(true, true);
                    }
                    if (Ext.getCmp('main-view-sidebar-menu'))
                        Ext.getCmp('main-view-sidebar-menu').setHidden(true);
                }
            };
            viewClasses[button.panelView.id] = Ext.getClassName(button.panelView);
            viewsA[button.panelView.id] = button;
            viewsP.push(button);
        }
        if (validPrivilegesOperatorComplex("access.view.caggregatedcore") && AppOPERATORFIRMA == null) {
            viewsP.push(getViewButton("aggregated-panel", Lang.zbiorczy_panel_uzytkownika, "iconAggregatedPanel", function () {
                performAjaxLPromise('rest/systemreq/updateContextSetHighestFirm').then(() => {
                    window.location = Config.prefixportalcorelink + '?aggregated=true';
                }).catch(error => {
                    handleCommonCallBackAjaxRPCError(error);
                });
            }, "p"));
        }
        viewsP.push(getViewButton("opc-konf", Lang.zmien_obecnego_operatora, "iconKonfOPC", function () {
            var w = Ext.create('WindowOperatorCurrentChange');
            w.showW();
        }, "p"));
        viewsP.push(getViewButton("about", Lang.o_programie, "iconAbout", function () {
            var w = Ext.create('WindowAbout');
            w.showW();
        }, "p"));
        var ttls = {
            p: 'Menu'
        };
        var viewsPP = {};
        Ext.each(viewsP, function (e) {
            if (!viewsPP[e.cat]) {
                viewsPP[e.cat] = {};
                viewsPP[e.cat].title = ttls[e.cat].capitalise();
                viewsPP[e.cat].buttons = [];
            }
            e.text = e.text.capitalise();
            if (e.text.length < 18) {
                var ll = 18 - e.text.length;
                ll = ll / 2;
                var ty = "";
                for (var i = 0; i < ll; i++)
                    ty += "&nbsp;";
                e.text = ty + e.text + ty;
            }
            viewsPP[e.cat].buttons.push(e);
        });
        var viewsPPP = [];
        for (var a in viewsPP) {
            viewsPPP.push(getViewPanelTab(viewsPP[a]));
        }
        var viewClsImageTitle = "main-panel-image-title4cloud-mobile";
        const controlHeightEl = document.getElementById('control-height');
        const addressBarHeight = controlHeightEl.clientHeight - window.innerHeight;
        if (Ext.os.is.Android) {
            ExtjsMobileBrowserWindowEvents.on('windowResized', () => {
                const viewPort = Ext.getCmp('main-viewport');
                const addressBarFill = Ext.getCmp('address-bar-fill');
                viewPort.setMinHeight(null);
                if (controlHeightEl.clientHeight == window.innerHeight) {
                    viewPort.setHeight(window.innerHeight);
                    addressBarFill.setHeight(0);
                } else {
                    viewPort.setHeight(window.innerHeight + addressBarHeight);
                    addressBarFill.setHeight(addressBarHeight);
                }
            }, this, {
                buffer: 100
            });
        }
        var viewPort = Ext.create('Ext.container.Viewport', {
            layout: 'border',
            id: 'main-viewport',
            border: false,
            minHeight: Ext.os.is.Android ? Ext.getBody().getViewSize().height + addressBarHeight :
                Ext.getBody().getViewSize().height,
            items: [{
                xtype: 'container',
                height: 50,
                layout: 'border',
                region: 'north',
                items: [{
                    xtype: 'button',
                    iconCls: 'burger-menu-button',
                    width: 50,
                    region: 'west',
                    listeners: {
                        scope: this,
                        click: function () {
                            let menu = Ext.getCmp('main-view-sidebar-menu');
                            if (menu)
                                menu.setHidden(!menu.hidden);
                        }
                    }
                }, {
                    id: "main-view-header",
                    xtype: 'panel',
                    region: 'center',
                    bodyCls: "cn-panel main-panel-image-title3",
                    height: 50,
                    border: false,
                    html: {
                        cls: "cn-panel",
                        cn: [{
                            cls: viewClsImageTitle
                        }, {
                            cls: "main-panel-image-title5-mobile"
                        }, {
                            cls: "main-panel-button-header-help-mobile",
                            id: "main-panel-button-header-help"
                        }, {
                            cls: "main-panel-button-header-logout-mobile",
                            id: "main-panel-button-header-logout"
                        }]
                    }
                }]
            }, {
                border: false,
                region: 'center',
                xtype: 'panel',
                layout: 'border',
                cls: Ext.os.deviceType != "Desktop" && Ext.os.name != "iOS" && Ext.browser.is("Chrome") ? "mobile-scaling" : null,
                items: [{
                    id: 'main-view-sidebar-menu',
                    border: true,
                    xtype: 'panel',
                    region: 'west',
                    multi: false,
                    width: '100%',
                    collapsible: false,
                    hidden: true,
                    layout: {
                        type: 'accordion'
                    },
                    cls: "main-view-accordion",
                    bodyStyle: 'background-color:#F1F1F1',
                    items: viewsPPP
                }, {
                    xtype: 'panel',
                    border: true,
                    bodyStyle: "border-top:0px;border-right:0px",
                    region: 'center',
                    layout: 'border',
                    items: [{
                        region: 'north',
                        border: false,
                        id: "main-view-northviews",
                        bodyCls: "main-panel-image-title1-mobile",
                        xtype: 'panel',
                        layout: 'fit',
                        height: 25,
                        html: {
                            cls: "cn-panel",
                            cn: [{
                                cls: "main-panel-image-title6-mobile"
                            }, {
                                cls: "main-panel-title-north",
                                id: "main-panel-title-north"
                            }]
                        }
                    }, {
                        border: true,
                        id: "main-view-contenerviews",
                        xtype: 'panel',
                        style: 'width: 100%;',
                        region: 'center',
                        layout: 'card',
                        layoutConfig: {
                            deferredRender: true
                        },
                        activeItem: 0,
                        items: []
                    }]
                },
                    Ext.os.is.Android ?
                        {
                            xtype: 'box',
                            id: 'address-bar-fill',
                            region: 'south',
                            height: addressBarHeight
                        } : {
                            hidden: true
                        }]
            }, {
                id: "main-view-statusbar",
                hidden: true,
                xtype: 'toolbar',
                region: 'south',
                height: 35,
                items: [{
                    id: "updateloginuser",
                    xtype: 'label',
                    style: 'display:block;margin-left:5px'
                }, "->", {
                    id: "totalinfo",
                    xtype: 'label',
                    style: 'display:block;margin-right:5px'
                }]
            }]
        });
        Ext.get("main-panel-button-header-help").setText(Lang.header_help);
        Ext.get("main-panel-button-header-help").on("click", function () {
            Ext.create('WindowHelp', {src: "https://staticcdn.smdp.online/help/help_arccan.html"}).showW();
        });
        Ext.get("main-panel-button-header-logout").setText(Lang.header_loguout);
        Ext.get("main-panel-button-header-logout").on("click", function () {
            window.location = "rest/systemreq/userSignOut";
        });
        Ext.defer(() => {
            var updateloginuser = Ext.getCmp("updateloginuser");
            updateloginuser.setText(AppOPERATOR.imie + " " + AppOPERATOR.nazwisko);
            var viewc = AppCLocationParams.uvroute;
            if (!viewc) {
                if (viewsA["dashboard"])
                    viewc = Ext.getCmp(viewsA["dashboard"].id);
                if (viewc) {
                    viewc.handler.call(viewc);
                }
            } else {
                viewc = Ext.getCmp(viewsA[viewc].id);
                viewc.handler.call(viewc);
                if (Ext.os.deviceType != "Desktop") {
                    var accordion = Ext.getCmp(viewc.panelHi).up();
                    accordion.on("expand", function () {
                        Ext.getCmp(viewc.panelHi).expand(2000);
                    }, null, {
                        single: true
                    });
                } else {
                    Ext.getCmp(viewc.panelHi).expand();
                }
            }
        }, 250);
    }

    applicationStartExd(UseMobileUI ? "main-cloud-45x-mobile" : "main-cloud-45x", function () {
        if (UseMobileUI) {
            initMainMobile();
        } else {
            initMain();
        }
    });

    return <></>
}

export default User;