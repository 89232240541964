Ext.define('ViewSterownikiMobile', {
    extend: 'Ext.panel.Panel',
    grid: null,
    windowObject: null,
    id: "sterowniki",
    rowContext: null,
    showState: false,
    statics: {
        accessPropertyViewName: "access.view.csterowniki"
    },
    cat: "p",
    text: Lang.sterowniki,
    buttonIconCls: "iconSterownik",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            scrollable: 'x',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    buildGrid: function () {
        var columnsArray = [{
            header: "ID",
            width: 145,
            sortable: true,
            dataIndex: 'id',
            filter: {
                type: 'numeric'
            },
            hidden: true
        }, {
            header: Lang.numer_seryjny,
            flex: 1,
            sortable: true,
            dataIndex: 'serialnumber',
            filter: {
                type: 'string'
            }
        },
            // {
            //     header: 'Dev ID',
            //     flex: 1,
            //     sortable: true,
            //     dataIndex: 'devid',
            //     filter: {
            //         type: 'string'
            //     }
            // },
            // {
            //     header: Lang.wersja,
            //     flex: 1,
            //     sortable: true,
            //     dataIndex: 'version',
            //     filter: {
            //         type: 'string'
            //     }
            // },
            {
                header: Lang.ostatnie_polaczenie,
                flex: 1,
                sortable: true,
                dataIndex: 'tmslastrespond',
                filter: {
                    type: 'date'
                }
            }];
        var recordType = [{
            name: 'id',
            mapping: 'id'
        }, {
            name: 'serialnumber',
            mapping: 'serialnumber'
        }, {
            name: 'devid',
            mapping: 'devid'
        }, {
            name: 'version',
            mapping: 'version'
        }, {
            name: 'tmslastrespond',
            mapping: 'tmslastrespond'
        }];
        let store = new Ext.data.Store({
            remoteSort: true,
            remoteFilter: true,
            autoLoad: false,
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: 'id',
                fields: recordType
            }),
            sorters: [{
                property: "tmslastrespond",
                direction: "DESC"
            }],
            proxy: {
                type: 'ajax',
                url: 'rest/sterowniki/getListOfUnattached',
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'id'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true,
                rowexpander: {
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">

                        <tpl if="serialnumber != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.numer_seryjny}:
                                    <span class="mobile-rowexp-value"> {serialnumber}</span>
                                </span>
                            </div>
                        </tpl>

                        <tpl if="devid != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">Dev ID:
                                    <span class="mobile-rowexp-value"> {devid}</span>
                                </span>
                            </div>
                        </tpl>
                        
                        <tpl if="version != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.wersja}:
                                    <span class="mobile-rowexp-value"> {version}</span>
                                </span>
                            </div>
                        </tpl>

                        <tpl if="tmslastrespond != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.ostatnie_polaczenie}:
                                    <span class="mobile-rowexp-value"> {tmslastrespond}</span>
                                </span>
                            </div>
                        </tpl>

                        </div>
                        `,
                        {
                            notEmptyAfterTrim
                        }
                    )
                }
            },
            store: store,
            columns: columnsArray,
            frame: false,
            border: false,
            stateId: "this.grid-" + this.id,
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagetoolbarown',
                displayInfo: true,
                pagingStore: store
            }
        });
    },
    refreshDatastore: function () {
        this.grid.store.load();
    },
    afterRender: function () {
        this.refreshDatastore();
        this.callParent(arguments);
    }
});
