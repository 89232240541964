import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Login from './components/Login/Login.js';
import User from './components/User/User.js';

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/:urlprefix*/login-old" component={Login}/>
                <Route path="/:urlprefix*/user-old" component={User}/>
                <Route path="/:urlprefix*/login" component={Login}/>
                <Route path="/:urlprefix*/user" component={User}/>
            </Switch>
        </Router>
    );
}

export default App;
