Ext.define('WindowResetPassword', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.resetuj_haslo,
    width: 500,
    padding: 10,
    closable: false,
    collapsible: false,
    firma: null,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    initComponent: function () {
        this.items = [{
            id: 'reset-panel',
            xtype: 'panel',
            layout: 'card',
            bbar: ['->', {
                xtype: 'button',
                itemId: 'anuluj-btn',
                text: Lang.anuluj,
                listeners: {
                    scope: this,
                    click: this.close
                }
            }, {
                xtype: 'button',
                itemId: 'reset-btn',
                text: Lang.resetuj_haslo,
                listeners: {
                    scope: this,
                    click: this.resetPassword
                }
            }
            ],
            items: [{
                itemId: 'card-0',
                items: [{
                    xtype: 'form',
                    defaults: {
                        anchor: '0'
                    },
                    items: [{
                        id: "reset-email",
                        xtype: 'textfield',
                        inputType: "email",
                        fieldLabel: Lang.podaj_email_do_resetu,
                        labelAlign: 'top'
                    }]
                }]
            }, {
                xtype: 'container',
                itemId: 'card-1',
                layout: 'center',
                items: [{
                    html: `${Lang.wyslano_link}`
                }]
            }]
        }];
        this.callParent(arguments);
    },
    showW: function () {
        let emailField = Ext.getCmp('reset-email');
        let login = Ext.getCmp("flogin").getValue().trim();
        emailField.setValue(login);
        enterHandler = () => this.resetPassword();
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.close
            }
        });
    },
    resetPassword: function () {
        enterHandler = () => this.close();
        let emailField = Ext.getCmp('reset-email');
        let email = emailField.getValue().trim();
        const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailReg.test(email)) {
            emailField.markInvalid(Lang.niepoprawny_email);
            return
        }
        this.mask(Lang.ladowanie);
        performAjaxLPromise('rest/systemreq/resetPassword', {email}).then(() => {
            this.down('#reset-panel').setActiveItem(1);
            this.down('#reset-btn').setHidden(true);
            this.down('#anuluj-btn').setText(Lang.zamknij);
            this.fireEvent('resetSuccess');
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        }).finally(() => {
            this.unmask();
        });
    },
    close: function () {
        this.fireEvent('closeSuccess');
        this.callParent(arguments);
    }
});