Ext.define('WindowOperatorCurrentChange', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.operator.capitalise(),
    width: 900,
    height: 625,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            border: false,
            deferredRender: false,
            anchor: '0',
            activeTab: 0,
            scrollable: true,
            items: [{
                title: Lang.dane_dostepu,
                xtype: 'panel',
                layout: 'column',
                items: [{
                    columnWidth: 1,
                    xtype: 'form',
                    itemId: 'formpanel',
                    bodyStyle: 'padding:10px 10px 10px 10px',
                    scrollable: true,
                    defaults: {
                        labelWidth: 150,
                        anchor: "0",
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        itemId: "currPass",
                        name: "currPass",
                        xtype: 'textfield',
                        inputType: 'password',
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        fieldLabel: Lang.obecne_haslo
                    }, {
                        itemId: "changepassword",
                        xtype: "checkboxexd",
                        checked: false,
                        hideLabel: true,
                        boxLabel: Lang.zmien_haslo,
                        itemCls: "operatorcurrentchange-small-top-margin",
                        listeners: {
                            change: function (el, newValue, oldValue, options) {
                                this.down("#pass").setDisabled(!newValue);
                                this.down("#pass2").setDisabled(!newValue);
                                var currPassCmp = this.down("#currPass");
                                currPassCmp.allowBlank = (!newValue && !this.down("#changelogin").getValue2()) || this.obiekt.integratedlogin;
                                currPassCmp.validate();
                            },
                            scope: this
                        }
                    }, {
                        itemId: "pass",
                        name: "pass",
                        xtype: 'textfield',
                        inputType: 'password',
                        allowBlank: false,
                        disabled: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        fieldLabel: Lang.haslo,
                        cls: "x-item-disabled-sec"
                    }, {
                        itemId: "pass2",
                        name: "pass2",
                        xtype: 'textfield',
                        inputType: 'password',
                        allowBlank: false,
                        disabled: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        fieldLabel: Lang.powtorz_haslo,
                        cls: "x-item-disabled-sec"
                    }, {
                        itemId: "changelogin",
                        xtype: "checkboxexd",
                        hideLabel: true,
                        boxLabel: Lang.zmien_email,
                        itemCls: "operatorcurrentchange-small-top-margin",
                        listeners: {
                            change: function (el, newValue, oldValue, options) {
                                this.down("#login").setDisabled(!newValue);
                                var currPassCmp = this.down("#currPass");
                                currPassCmp.allowBlank = (!newValue && !this.down("#changepassword").getValue2()) || this.obiekt.integratedlogin;
                                currPassCmp.validate();
                            },
                            scope: this
                        }
                    }, {
                        itemId: "login",
                        name: "login",
                        xtype: 'textfield',
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        fieldLabel: Lang.email,
                        disabled: true,
                        cls: "x-item-disabled-sec",
                        regex: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        invalidText: Lang.niepopawny_email
                    }]
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            itemId: 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            itemId: 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        performAjaxLPromise("rest/operators/getCurrentLogged").then(data => {
            this.show();
            this.center();
            this.setKeyMap({
                'ESC': {
                    scope: this,
                    handler: this.closeOp
                }
            });
            var obiekt = data;
            this.obiekt = obiekt;
            this.down("#login").setValue(data.login);
        }).catch(error => {
            this.closeOp();
            handleCommonCallBackAjaxRPCError(error);
        });
    },
    saveOp: function () {
        if (!this.down("#changepassword").getValue() && !this.down("#changelogin").getValue()) {
            Ext.MessageBox.alert(Lang.error, Lang.wybierz_przynajmniej_jedna_opcje);
            return;
        }
        if (!this.down("#formpanel").form.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        var pass = null,
            pass2 = null,
            login = null;
        var currPass = this.down("#currPass").getValue().trim();
        if (currPass == "" && (this.down("#changepassword").getValue() || this.down("#changelogin").getValue()) && !this.obiekt.integratedlogin) {
            this.down("#currPass").markInvalid(Lang.pole_nie_moze_byc_puste);
            return;
        }
        if (this.down("#changepassword").getValue()) {
            pass = this.down("#pass").getValue().trim();
            pass2 = this.down("#pass2").getValue().trim();
            if (pass == "" || pass2 == "" || pass != pass2) {
                this.down("#pass").markInvalid(Lang.puste_haslo_lub_bledna_weryfikacja);
                this.down("#pass2").markInvalid(Lang.puste_haslo_lub_bledna_weryfikacja);
                return;
            }
        }
        if (this.down("#changelogin").getValue()) {
            login = this.down("#login").getValue().trim();
            if (login == "") {
                this.down("#login").markInvalid(Lang.pole_nie_moze_byc_puste);
                return;
            }
        }
        this.mask(Lang.ladowanie);
        let newOpData = {
            pass,
            currPass,
            login
        };
        performAjaxLPromise("rest/operators/saveCurrentLogged", newOpData).then(data => {
            AppOPERATOR.login = data;
            this.closeOp();
            Ext.MessageBox.alert("", Lang.operacjawykonana);
        }).catch(error => {
            this.unmask();
            handleCommonCallBackAjaxRPCError(error);
        });
    },
    closeOp: function () {
        this.close();
    }
});

