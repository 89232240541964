Ext.define('WindowOrderDetails', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.rachunek,
    cls: 'a-WindowOrderDetails',
    width: 1200,
    maxHeight: 800,
    padding: 10,
    collapsible: false,
    numerrachunku: null,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    rachunek: null,
    grid: null,
    initComponent: function () {
        this.buildGrid();
        let fakturaButtons = [{
            xtype: 'button',
            itemId: 'faktura-btn',
            maxWidth: 150,
            margin: '0 0 0 10',
            text: Lang.zalacz,
            listeners: {
                scope: this,
                click: this.zalaczFakture
            },
            hidden: isValidOperatorFirmaRaw(),
            disabled: !validPrivilegesOperatorComplex("access.manage.crachunki.faktury")
        }, {
            xtype: 'button',
            itemId: 'download-faktura-btn',
            maxWidth: 150,
            margin: '0 0 0 10',
            text: Lang.pobierz,
            listeners: {
                scope: this,
                click: this.pobierzFakture
            },
            disabled: !validPrivilegesOperatorComplex("access.manage.crachunki.faktury")
        }];
        let proformaButtons = [{
            xtype: 'button',
            itemId: 'proforma-btn',
            maxWidth: 150,
            margin: '0 0 0 10',
            text: Lang.zalacz,
            listeners: {
                scope: this,
                click: this.zalaczProforme
            },
            hidden: isValidOperatorFirmaRaw(),
            disabled: !validPrivilegesOperatorComplex("access.manage.crachunki.faktury")
        }, {
            xtype: 'button',
            itemId: 'download-proforma-btn',
            maxWidth: 150,
            margin: '0 0 0 10',
            text: Lang.pobierz,
            listeners: {
                scope: this,
                click: this.pobierzProforme
            },
            disabled: !validPrivilegesOperatorComplex("access.manage.crachunki.faktury")
        }];
        if (WindowExdMobileDEVICEV) {
            fakturaButtons = [{
                xtype: 'container',
                width: '100%',
                layout: {
                    type: 'hbox',
                    align: 'center',
                    pack: 'center'
                },
                items: [...fakturaButtons]
            }];
        }
        if (WindowExdMobileDEVICEV) {
            proformaButtons = [{
                xtype: 'container',
                width: '100%',
                layout: {
                    type: 'hbox',
                    align: 'center',
                    pack: 'center'
                },
                items: [...proformaButtons]
            }];
        }
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            items: [{
                xtype: 'form',
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0",
                    labelWidth: 150,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                frame: false,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: true,
                items: [{
                    xtype: 'toolbar',
                    docked: 'top',
                    scrollable: true,
                    padding: 10,
                    margin: '0 0 10 0',
                    items: [{
                        itemId: 'oplacenie-btn',
                        xtype: 'button',
                        iconCls: 'check-icon',
                        iconAlign: 'left',
                        maxWidth: 150,
                        text: Lang.oznacz_oplacenie,
                        listeners: {
                            scope: this,
                            click: this.oznaczOplacenie
                        },
                        hidden: isValidOperatorFirmaRaw(),
                        disabled: !validPrivilegesOperatorComplex("access.manage.crachunki.statemanage")
                    }, {
                        itemId: 'zatwierdzenie-btn',
                        xtype: 'button',
                        iconCls: 'check-circle-icon',
                        iconAlign: 'left',
                        maxWidth: 150,
                        text: Lang.zatwierdz,
                        listeners: {
                            scope: this,
                            click: this.oznaczZatwierdzenie
                        },
                        hidden: isValidOperatorFirmaRaw(),
                        disabled: !validPrivilegesOperatorComplex("access.manage.crachunki.statemanage")
                    }, {
                        itemId: 'delete-btn',
                        xtype: 'button',
                        iconCls: 'cancel-icon',
                        iconAlign: 'left',
                        maxWidth: 150,
                        text: Lang.usun,
                        listeners: {
                            scope: this,
                            click: this.deleteOrder
                        },
                        hidden: isValidOperatorFirmaRaw(),
                        disabled: !validPrivilegesOperatorComplex("access.manage.crachunki.statemanage")
                    }]
                }, {
                    itemId: "numerrachunku",
                    name: "numerrachunku",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.numer_rachunku,
                    maxLength: 16,
                    editable: false,
                    allowBlank: true
                }, {
                    itemId: "flagaoplacenia",
                    name: "flagaoplacenia",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.oplacono,
                    editable: false,
                    allowBlank: true
                }, {
                    itemId: "flagazatwierdzenia",
                    name: "flagazatwierdzenia",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.zatwierdzono,
                    editable: false,
                    allowBlank: true
                }, {
                    xtype: 'container',
                    layout: WindowExdMobileDEVICEV ? 'vbox' : 'hbox',
                    defaults: {
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        itemId: "flagawyslaniafaktura",
                        labelWidth: 150,
                        margin: '0 0 10 0',
                        width: WindowExdMobileDEVICEV ? '100%' : 'auto',
                        name: "flagawyslaniafaktura",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.faktura,
                        editable: false,
                        allowBlank: true
                    }, ...fakturaButtons]
                }, {
                    xtype: 'container',
                    layout: WindowExdMobileDEVICEV ? 'vbox' : 'hbox',
                    defaults: {
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        itemId: "flagawyslaniaproforma",
                        labelWidth: 150,
                        margin: '0 0 10 0',
                        width: WindowExdMobileDEVICEV ? '100%' : 'auto',
                        name: "flagawyslaniaproforma",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.proforma,
                        editable: false,
                        allowBlank: true
                    }, ...proformaButtons]
                }, {
                    itemId: "datautworzenia",
                    name: "datautworzenia",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.data_utworzenia,
                    editable: false,
                    allowBlank: true
                }, {
                    itemId: "userimienazwisko",
                    name: "userimienazwisko",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.operator,
                    editable: false,
                    allowBlank: true
                }, {
                    xtype: 'container',
                    layout: {
                        type: "hbox",
                        align: "stretch"
                    },
                    defaults: {
                        labelAlign: 'left'
                    },
                    border: 1,
                    style: {
                        borderColor: 'grey',
                        borderStyle: 'solid'
                    },
                    hidden: !(AppOPERATOR.id == Const.OPERATOR_SERWIS_ID1 || AppOPERATORMNGFIRMAID != null) || AppOPERATORFIRMA != null,
                    padding: '10 10 10 10',
                    margin: '20 0 20 0',
                    items: [{
                        itemId: "rozliczono_arccan",
                        name: "rozliczono_arccan",
                        xtype: 'checkboxexd',
                        labelWidth: 150,
                        fieldLabel: Lang.rozliczono_arccan,
                        editable: true,
                        hidden: AppOPERATOR.id != Const.OPERATOR_SERWIS_ID1
                    }, {
                        itemId: "zamowienie_kontr",
                        name: "zamowienie_kont",
                        xtype: 'textfieldexd',
                        labelWidth: 150,
                        fieldLabel: Lang.zamowienie_kontr,
                        editable: true,
                        margin: '0 0 0 20'
                    }, {
                        xtype: 'button',
                        itemId: 'save-admin-props-btn',
                        maxWidth: 150,
                        text: Lang.zapisz,
                        handler: this.saveAdminProps,
                        margin: '0 0 0 40',
                        scope: this
                    }]
                }, {
                    region: "center",
                    xtype: 'panel',
                    frame: false,
                    border: false,
                    layout: 'fit',
                    items: [this.grid]
                }]
            }]
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.close
            }
        });
        this.refreshData();
    },
    close: function () {
        this.fireEvent('closeSuccess');
        this.callParent(arguments);
    },
    refreshData: function () {
        this.mask(Lang.ladowanie);
        performAjaxLPromise('rest/billingHistory/getOrderByNumber', {
            numerrachunku: this.numerrachunku
        }).then(data => {
            this.rachunek = data;
            let {
                numerrachunku,
                datautworzenia,
                flagaoplacenia,
                flagazatwierdzenia,
                flagawyslaniafaktura,
                details,
                flagazalaczeniafaktura,
                flagazalaczeniaproforma,
                flagawyslaniaproforma,
                rozliczono_arccan,
                zamowienie_kontr,
                userimienazwisko
            } = this.rachunek;
            this.down('#numerrachunku').setValue(numerrachunku);
            this.down('#datautworzenia').setValue(datautworzenia);
            this.down('#flagaoplacenia').setValue(flagaoplacenia ? Lang.tak : Lang.nie);
            this.down('#flagazatwierdzenia').setValue(flagazatwierdzenia ? Lang.tak : Lang.nie);
            this.down('#flagawyslaniafaktura').setValue(flagazalaczeniafaktura ?
                Lang.zalaczono : Lang.nie_zalaczono);
            this.down('#flagawyslaniaproforma').setValue(flagazalaczeniaproforma ?
                Lang.zalaczono : Lang.nie_zalaczono);
            this.down('#download-faktura-btn').setDisabled(!flagazalaczeniafaktura);
            this.down('#download-proforma-btn').setDisabled(!flagazalaczeniaproforma);
            this.down('#oplacenie-btn').setDisabled(flagaoplacenia);
            this.down('#delete-btn').setDisabled(flagaoplacenia || flagazatwierdzenia);
            this.down('#zatwierdzenie-btn').setDisabled(flagazatwierdzenia);
            this.down('#faktura-btn').setDisabled(flagawyslaniafaktura);
            this.down('#proforma-btn').setDisabled(flagawyslaniaproforma);
            this.down('#rozliczono_arccan').setValue(rozliczono_arccan);
            this.down('#zamowienie_kontr').setValue(zamowienie_kontr);
            this.down('#userimienazwisko').setValue(userimienazwisko);
            this.grid.store.loadData(details);
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        }).finally(() => {
            this.unmask();
        });
    },
    oznaczOplacenie: function () {
        let headerMessage = Lang.oznacz_oplacenie;
        let message = Lang.czy_na_pewno_oznaczyc_oplacony;
        Ext.MessageBox.confirm(headerMessage, message, btn => {
            if (btn.toLowerCase() == 'yes') {
                this.mask(Lang.ladowanie);
                performAjaxLPromise('rest/billingHistory/setPaidByNumber', {numerrachunku: this.rachunek.numerrachunku}).then(() => {
                    this.refreshData();
                }).catch(error => {
                    handleCommonCallBackAjaxRPCError(error);
                }).finally(() => {
                    this.unmask();
                });
            }
        });
    },
    oznaczZatwierdzenie: function () {
        let headerMessage = Lang.oznacz_zatwierdzenie;
        let message = Lang.czy_na_pewno_oznaczyc_zatwierdzony;
        Ext.MessageBox.confirm(headerMessage, message, btn => {
            if (btn.toLowerCase() == 'yes') {
                this.mask(Lang.ladowanie);
                performAjaxLPromise('rest/billingHistory/setApprovedByNumber', {numerrachunku: this.rachunek.numerrachunku}).then(() => {
                    this.refreshData();
                }).catch(error => {
                    handleCommonCallBackAjaxRPCError(error);
                }).finally(() => {
                    this.unmask();
                });
            }
        });
    },
    saveAdminProps: function () {
        let headerMessage = Lang.zapisz_zmiany;
        let message = Lang.czy_na_pewno_zapisac_zmiany;
        Ext.MessageBox.confirm(headerMessage, message, btn => {
            if (btn.toLowerCase() == 'yes') {
                this.mask(Lang.ladowanie);
                performAjaxLPromise('rest/billingHistory/saveAdminProps', {
                    numerrachunku: this.rachunek.numerrachunku,
                    rozliczono_arccan: this.down('#rozliczono_arccan').getValue(),
                    zamowienie_kontr: this.down('#zamowienie_kontr').getValue()
                }).then(() => {
                    this.refreshData();
                }).catch(error => {
                    handleCommonCallBackAjaxRPCError(error);
                }).finally(() => {
                    this.unmask();
                });
            }
        });
    },
    zalaczFakture: function () {
        let windowZalaczFakture = Ext.create('WindowZalaczFakture', {rachunek: this.rachunek});
        windowZalaczFakture.showW();
        windowZalaczFakture.on('closeSuccess', this.refreshData, this);
    },
    pobierzFakture: function () {
        postFormAsync('rest/billingHistory/downloadVatInvoice', {
            numerrachunku: this.numerrachunku
        }, null);
    },
    zalaczProforme: function () {
        let windowZalaczProforme = Ext.create('WindowZalaczProforme', {rachunek: this.rachunek});
        windowZalaczProforme.showW();
        windowZalaczProforme.on('closeSuccess', this.refreshData, this);
    },
    pobierzProforme: function () {
        postFormAsync('rest/billingHistory/downloadVatInvoice?proforma=true', {
            numerrachunku: this.numerrachunku
        }, null);
    },
    deleteOrder: function () {
        let headerMessage = Lang.usun;
        let message = Lang.czy_na_pewno_wycofac_rachunek;
        Ext.MessageBox.confirm(headerMessage, message, btn => {
            if (btn.toLowerCase() == 'yes') {
                this.mask(Lang.ladowanie);
                performAjaxLPromise('rest/billingHistory/deleteOrderByNumber', {numerrachunku: this.rachunek.numerrachunku}).then(() => {
                    this.close();
                }).catch(error => {
                    handleCommonCallBackAjaxRPCError(error);
                }).finally(() => {
                    this.unmask();
                });
            }
        });
    },
    buildGrid: function () {
        var cols = [{
            header: "UUID",
            flex: 1,
            sortable: true,
            dataIndex: 'uuid',
            hidden: true
        }, {
            header: Lang.typ_pozycji,
            flex: 2,
            sortable: true,
            dataIndex: 'typpozycji',
            renderer: this.typPozycjiRenderer
        }, {
            header: Lang.numer_seryjny,
            flex: 2,
            sortable: true,
            dataIndex: 'serialnumber',
            hidden: WindowExdMobileDEVICEV
        }, {
            header: Lang.poprzedni_typ_systemu,
            flex: 1,
            sortable: true,
            dataIndex: 'typsystemuhardwareold',
            renderer: this.typSystemuHardwareRenderer
        }, {
            header: Lang.nowy_typ_systemu,
            flex: 1,
            sortable: true,
            dataIndex: 'typsystemuhardwarenew',
            renderer: this.typSystemuHardwareRenderer
        }, {
            header: Lang.typ_kostki,
            flex: 2,
            sortable: true,
            dataIndex: 'kostkaname'
        }, {
            header: Lang.ilosc_kostek,
            flex: 1,
            dataIndex: 'ilosckostek'
        }, {
            header: Lang.rozliczono,
            flex: 1,
            dataIndex: 'rozliczone',
            renderer: renderBool4,
            filter: {
                type: 'boolean'
            }
        }];
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true,
                rowexpander: !WindowExdMobileDEVICEV ? null : {
                    rowBodyTpl: new Ext.XTemplate(
                        `<div class="mobile-rowexp-wrapper">
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.typ_pozycji}:
                                <span class="mobile-rowexp-value"> {typpozycji:this.typPozycji}</span>
                            </span>
                        </div>

                        <tpl if="serialnumber != null">
                            <div class="mobile-rowexp-row">
                                <span class="mobile-rowexp-title">${Lang.numer_seryjny}:
                                    <span class="mobile-rowexp-value"> {serialnumber}</span>
                                </span>
                            </div>
                        </tpl>

                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.poprzedni_typ_systemu}:
                                <span class="mobile-rowexp-value"> {typsystemuhardwareold:this.rodzajAbonamentu}</span>
                            </span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.nowy_typ_systemu}:
                                <span class="mobile-rowexp-value"> {typsystemuhardwarenew:this.rodzajAbonamentu}</span>
                            </span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.typ_kostki}:
                                <span class="mobile-rowexp-value"> {kostkaname}</span>
                            </span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.ilosc_kostek}:
                                <span class="mobile-rowexp-value"> {ilosckostek}</span>
                            </span>
                        </div>
                        
                        <div class="mobile-rowexp-row">
                            <span class="mobile-rowexp-title">${Lang.rozliczono}:
                                <span class="mobile-rowexp-value"> {rozliczone:this.yesNo}</span>
                            </span>
                        </div>
                    </div>`,
                        {
                            typPozycji: this.typPozycjiRenderer,
                            rodzajAbonamentu: this.typSystemuHardwareRenderer,
                            yesNo: renderBool4,
                            notEmptyAfterTrim
                        }
                    )
                }
            },
            itemId: 'details-grid',
            store: new Ext.data.ArrayStore({
                data: []
            }),
            columns: cols,
            minHeight: 300,
            frame: false,
            border: false,
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            }
        });
    },
    typPozycjiRenderer: function (value) {
        return TYP_POZYCJI_FBSH_DATA.find(typ => typ[0] == value)[1]
    },
    typSystemuHardwareRenderer: function (value) {
        if (value == null) {
            return '';
        }
        return TYP_SYSTEMU_DATA.find(typ => typ[0] == value)[1]
    }
});