Ext.define('WindowFirma', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.firma.capitalise(),
    width: 800,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    dashboard: false,
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            border: false,
            deferredRender: false,
            activeTab: 0,
            items: [{
                itemId: "formpanel1",
                title: Lang.ogolne.capitalise(),
                xtype: 'form',
                frame: true,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: true,
                labelWidth: 150,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0",
                    labelWidth: 150,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    itemId: "nazwa",
                    name: "nazwa",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.nazwa,
                    allowBlank: false,
                    maxLength: 200,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    labelStyle: "margin-top:10px",
                    labelWidth: 150,
                    style: "margin-top:10px",
                    anchor: "0",
                    itemId: "opis",
                    name: "opis",
                    isFormField: true,
                    fieldLabel: Lang.opis,
                    xtype: 'textareaexd'
                }, {
                    itemId: "adres",
                    name: "adres",
                    xtype: 'textareaexd',
                    fieldLabel: Lang.adres,
                    allowBlank: false,
                    maxLength: 150,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    itemId: "telefon",
                    name: "telefon",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.telefon,
                    allowBlank: false,
                    maxLength: 12,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    itemId: "nip",
                    name: "nip",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.nip,
                    allowBlank: false,
                    maxLength: 25,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    itemId: "kraj",
                    name: "kraj",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.kraj,
                    allowBlank: false,
                    maxLength: 56,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    itemId: "email",
                    name: "email",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.email,
                    allowBlank: false,
                    maxLength: 54,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    itemId: "typSystemu",
                    name: "typSystemu",
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: TYP_SYSTEMU_DATA,
                        fields: ['value', 'text']
                    }),
                    fieldLabel: Lang.typ_systemu,
                    displayField: 'text',
                    valueField: 'value',
                    triggerAction: 'all',
                    resizable: false,
                    mode: 'local',
                    editable: false,
                    listeners: {
                        scope: this
                    },
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    itemId: "lang",
                    name: "lang",
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [],
                        fields: ['value', 'text']
                    }),
                    fieldLabel: Lang.jezyk,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    displayField: 'text',
                    valueField: 'value',
                    triggerAction: 'all',
                    resizable: false,
                    mode: 'local',
                    editable: false,
                    listeners: {
                        scope: this
                    }
                }, {
                    itemId: 'mngselector',
                    name: 'mngselector',
                    xtype: 'treepickerexd',
                    store: createTreeStore(),
                    maxPickerHeight: WindowExdMobileDEVICEV ? 200 : 300,
                    displayField: "text",
                    resizable: false,
                    editable: false,
                    allowBlank: false,
                    fieldLabel: Lang.firma_zarzadzajaca
                }, {
                    itemId: "timezone",
                    name: "timezone",
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [],
                        fields: ['value', 'text'],
                        sorters: [{
                            property: "text",
                            direction: "ASC"
                        }]
                    }),
                    fieldLabel: Lang.strefa_czasowa,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    displayField: 'text',
                    valueField: 'value',
                    triggerAction: 'all',
                    resizable: false,
                    mode: 'local',
                    editable: false,
                    listeners: {
                        scope: this
                    }
                }, {
                    itemId: "istest",
                    value: false,
                    xtype: 'checkboxexd',
                    hidden: AppOPERATOR.id != Const.OPERATOR_SERWIS_ID1,
                    boxLabel: Lang.firma_testowa
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            itemId: 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            itemId: 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        this.obiektid = obiektid;
        this.obiekt = null;
        const mngSelector = this.down('#mngselector');
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        performAjaxLPromise('rest/systemkonf/readLangList').then(data => {
            let languages = data.map(lang => [lang.value, lang.text]);
            this.down('#lang').store.loadData(languages);
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        });
        performAjaxLPromise('rest/systemkonf/readTimezoneList').then(data => {
            let timezones = data.map(tz => [tz.value, tz.text]);
            this.down('#timezone').store.loadData(timezones);
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        });
        performAjaxLPromise('rest/firmyzarzadzajace/getList').then(data => {
            const children = generateFirmaTreePanelChildren(data);
            mngSelector.setStore(createTreeStore(children));
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        });
        if (obiektid != 0) {
            this.down("#typSystemu").setVisible(false);
            this.down("#istest").setVisible(false);
            this.mask(Lang.ladowanie);
            performAjaxLPromise('rest/firmy/getObjectForEdit', {id: obiektid}).then((data) => {
                this.obiekt = data;
                this.down("#nazwa").setValue(this.obiekt.nazwa);
                this.down("#opis").setValue(this.obiekt.opis);
                this.down("#adres").setValue(this.obiekt.adres);
                this.down("#telefon").setValue(this.obiekt.telefon);
                this.down("#nip").setValue(this.obiekt.nip);
                this.down('#kraj').setValue(this.obiekt.kraj);
                this.down("#email").setValue(this.obiekt.email);
                this.down("#typSystemu").setValue(this.obiekt.billingInfo.typSystemu);
                this.down("#lang").setValue(this.obiekt.lang);
                this.down('#istest').setValue(this.obiekt.istest);
                this.down('#timezone').setValue(this.obiekt.timezone)
                mngSelector.setValue(this.obiekt.firmaZarzadzajacaId);
                mngSelector.getPicker().expandPath("".concat(this.obiekt.firmaZarzadzajacaId));
            }).catch(error => {
                handleCommonCallBackAjaxRPCError(error);
            }).finally(() => {
                this.unmask();
            });
        }
    },
    saveOp: function () {
        let emailField = this.down("#email");
        const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailReg.test(emailField.getValue())) {
            emailField.markInvalid(Lang.niepoprawny_email);
            return;
        }
        var canWrite = this.down('#formpanel1').isValid();
        if (canWrite) {
            var isTest = this.down("#istest").getValue();
            var nazwaFromField = this.down("#nazwa").getValue();
            var nazwa = isTest ? (nazwaFromField.indexOf('(TEST)') != -1 ? nazwaFromField.substr(0, nazwaFromField.indexOf('(TEST)')) : nazwaFromField) : nazwaFromField;
            this.mask(Lang.zapisywanie);
            let newFirma = {
                id: this.obiektid,
                nazwa: nazwa,
                opis: this.down("#opis").getValue(),
                adres: this.down("#adres").getValue(),
                telefon: this.down("#telefon").getValue(),
                nip: this.down("#nip").getValue(),
                kraj: this.down('#kraj').getValue(),
                email: emailField.getValue(),
                lang: this.down("#lang").getValue(),
                timezone: this.down("#timezone").getValue(),
                istest: isTest,
                firmaZarzadzajacaId: this.down('#mngselector').getValue(),
                billingInfo: {
                    typSystemu: this.down("#typSystemu").getValue()
                }
            };
            let endpoint;
            if (this.obiektid == 0) {
                endpoint = 'createObject';
            } else if (this.dashboard) {
                endpoint = 'saveCurrentObject';
            } else {
                endpoint = 'saveObject';
            }
            performAjaxLPromise(`rest/firmy/${endpoint}`, newFirma).then(() => {
                this.fireEvent('closeSuccess');
                this.close();
            }).catch((error) => {
                this.unmask();
                handleCommonCallBackAjaxRPCError(error);
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
