Ext.define('WindowAbout', {
    extend: 'Ext.ux.window.WindowExd',
    width: 370,
    height: 288,
    frame: false,
    id: 'WindowAbout',
    border: false,
    header: false,
    closable: false,
    plain: true,
    style: "background-color: transparent; border-style: none;",
    shadow: false,
    collapsible: false,
    modal: true,
    resizable: false,
    constrain: false,
    constrainHeader: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        var imageClass1 = "about-image-cloud3";
        this.items = [{
            xtype: 'panel',
            bodyStyle: 'background-color: #e4e4e4;border:0px',
            defaults: {
                bodyStyle: 'background-color: #e4e4e4;border:0px',
                style: 'background-color: #e4e4e4;border:0px'
            },
            items: [{
                xtype: 'box',
                border: false,
                frame: false,
                width: '100%',
                height: '80px',
                cls: imageClass1,
                html: [{
                    tag: "div",
                    style: "position:absolute;top:3px;left:15px;z-index:2;color:black;font: bold 11px arial, tahoma, verdana, sans-serif",
                    html: Lang.o_programie.capitalise()
                }, {
                    id: this.cid + "arccan",
                    tag: "div",
                    style: `position:absolute;top:${WindowExdMobileDEVICEV && Ext.getBody().getViewSize().width < 340 ? '55' : '63'}px;left:20px;z-index:2;color:#dc5b00;font: bold 9px arial, tahoma, verdana, sans-serif;cursor:pointer`,
                    html: "www.arccan.eu"
                }, {
                    tag: "div",
                    style: "position:absolute;" + "top:85px" + ";right:7px;z-index:2;font: bold 12px arial, tahoma, verdana, sans-serif",
                    html: 'Copyright &copy; ' + new Date().getFullYear()
                }, {
                    tag: "div",
                    style: "position:absolute;" + (true ? "top:118px" : "top:95px") + ";left:70px;z-index:2;color:#dc5b00;font: bold 12px arial, tahoma, verdana, sans-serif",
                    html: Lang.wersja.capitalise() + ":"
                }, {
                    tag: "div",
                    style: "position:absolute;" + (true ? "top:118px" : "top:95px") + ";left:125px;z-index:2;color:black;font: bold 12px arial, tahoma, verdana, sans-serif",
                    html: Const.VERSIONEND
                }, {
                    id: this.cid + 'btnpaneldiv',
                    tag: "div",
                    style: "position: absolute; top: 250%;  width: 100%; height: 20px;"
                }]
            }, {
                layout: 'hbox',
                width: '100%',
                style: 'padding: 60px 0 0 70px;background-color: #e4e4e4;',
                items: [{
                    html: Lang.wersja_gui.capitalise(),
                    bodyStyle: 'color:#dc5b00;font: bold 12px arial, tahoma, verdana, sans-serif;background-color: #e4e4e4;'
                }, {
                    html: Lang.stary.capitalise() + "/",
                    bodyStyle: 'font: bold 12px arial, tahoma, verdana, sans-serif; padding-left: 15px;background-color: #e4e4e4;'
                }, {
                    html: Lang.nowy.capitalise(),
                    width: '100%',
                    bodyStyle: 'color:#dc5b00;font:normal 12px arial, tahoma, verdana, sans-serif;background-color: #e4e4e4;cursor: pointer;',
                    listeners: {
                        render: function (c) {
                            c.getEl().on('click', function () {
                                if (AppOPERATOR.id == Const.OPERATOR_SERWIS_ID1) {
                                    window.location.href = Config.preprefixportallink + '?newui=true';
                                }
                            });
                        }
                    }
                }]
            }]
        }];

        this.callParent(arguments);
    },
    showW: function () {
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        Ext.create('Ext.panel.Panel', {
            renderTo: Ext.get(this.cid + 'btnpaneldiv'),
            id: this.cid + 'btnpanel',
            style: 'display: flex; justify-contnet: center; align-items: center;',
            cls: 'about-btnpanel',
            layout: {
                type: 'hbox',
                align: 'stretch',
                pack: 'center'
            },
            items: [{
                xtype: 'button',
                id: this.cid + "okb",
                text: Lang.ok.capitalise(),
                height: 20,
                scale: 'small',
                width: 65,
                listeners: {
                    scope: this,
                    click: function () {
                        this.closeOp();
                    }
                }
            }]
        });
        if (Const.ENABLECUSTOMVENDOR) {
            Ext.get(this.cid + "arccan").hide();
        }
        Ext.get(this.cid + "arccan").on("click", function () {
            window.location = "http://www.arccan.eu";
        }, this);
        Ext.get(this.cid + "arccan").on("mouseover", function (e, t, o) {
            Ext.get(t).applyStyles("border:1px solid #dc5b00;padding:1px;position:absolute;top:61px;left:18px");
        }, this);
        Ext.get(this.cid + "arccan").on("mouseout", function (e, t, o) {
            Ext.get(t).applyStyles("border:0px solid #dc5b00;padding:0px;position:absolute;top:63px;left:20px");
        }, this);
    },
    closeOp: function () {
        this.close();
    }
});
