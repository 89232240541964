Ext.define('WindowZalaczProforme', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.zalacz_proforme,
    cls: 'a-WindowZalaczProforme',
    width: 500,
    padding: 10,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    rachunek: null,
    initComponent: function () {
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            items: [{
                itemId: 'proforma-form',
                xtype: 'form',
                defaults: {
                    anchor: '0',
                    labelWidth: 80
                },
                items: [{
                    itemId: "flagazalaczeniaproforma",
                    name: "flagazalaczeniaproforma",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.proforma,
                    editable: false,
                    allowBlank: true
                }, {
                    itemId: 'zalaczona-proforma',
                    xtype: 'filefieldexd',
                    name: 'file',
                    allowBlank: false,
                    buttonText: Lang.zalacz,
                    msgTarget: 'under'
                }]
            }],
            bbar: ['->', {
                text: Lang.wyslij,
                iconCls: 'send-icon',
                iconAlign: 'right',
                listeners: {
                    scope: this,
                    click: this.wyslijProforme
                }
            }]
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.down('#flagazalaczeniaproforma').setValue(this.rachunek.flagazalaczeniaproforma ?
            Lang.zalaczono : Lang.nie_zalaczono);
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.close
            }
        });
    },
    close: function () {
        this.fireEvent('closeSuccess');
        this.callParent(arguments);
    },
    wyslijProforme: function () {
        let form = this.down('#proforma-form');
        if (form.isValid()) {
            this.mask(Lang.ladowanie);
            sendAjaxFormJsonRpcL('rest/billingHistory/uploadVatInvoice?proforma=true', {
                numerrachunku: this.rachunek.numerrachunku
            }, form.getForm(), this, function (data) {
                if (!data.status) {
                    this.unmask();
                    Ext.MessageBox.alert(Lang.error, data.msg);
                } else {
                    this.close();
                }
            }, 0);
        }
    }
});