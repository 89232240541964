Ext.define('ViewFirmas', {
    extend: 'Ext.panel.Panel',
    cls: 'a-ViewFirmas',
    grid: null,
    gridpage: null,
    windowObject: null,
    id: "firmas",
    taskContextMenu: null,
    rowContext: null,
    showState: false,
    statics: {
        accessPropertyManageName: "access.manage.cfirmy",
        accessPropertyViewName: "access.view.cfirmy"
    },
    cat: "p",
    text: Lang.firmy,
    buttonIconCls: "iconFirma",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewFirmas.accessPropertyManageName)
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                scope: this,
                text: Lang.edytuj.capitalise(),
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewFirmas.accessPropertyManageName)
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                scope: this,
                text: Lang.usun.capitalise(),
                handler: this.usunObiekt,
                hidden: true,
                disabled: !validPrivilegesOperatorComplex(ViewFirmas.accessPropertyManageName)
            }, "-", {
                iconAlign: 'left',
                iconCls: "iconNew",
                text: `${Lang.otworz} ${Lang.dashboard}`,
                scope: this,
                handler: this.fetchDashboard,
                disabled: !validPrivilegesOperatorComplex(ViewDashboard.accessPropertyViewName)
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    scrollToView: function (id) {
        this.grid.selectRowScroll("id", this.id);
    },
    buildGrid: function () {
        var columnsArray = [{
            header: "ID",
            width: 145,
            sortable: true,
            dataIndex: 'id',
            filter: {
                type: 'numeric'
            },
            hidden: true
        }, {
            header: Lang.nazwa,
            flex: 2,
            sortable: true,
            dataIndex: 'nazwa',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.opis,
            flex: 2,
            sortable: true,
            dataIndex: 'opis',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.adres,
            flex: 2,
            sortable: true,
            dataIndex: 'adres',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.telefon,
            flex: 2,
            sortable: true,
            dataIndex: 'telefon',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.nip,
            flex: 2,
            sortable: true,
            dataIndex: 'nip',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.kraj,
            flex: 2,
            sortable: true,
            dataIndex: 'kraj',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.email,
            flex: 2,
            sortable: true,
            dataIndex: 'email',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.typ_systemu,
            flex: 2,
            sortable: false,
            dataIndex: 'typSystemu',
            renderer: function (value, record) {
                return TYP_SYSTEMU_DATA.find(type => type[0] == value)[1]
            }
        }, {
            header: Lang.jezyk,
            flex: 2,
            sortable: true,
            dataIndex: 'lang',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.strefa_czasowa,
            flex: 2,
            sortable: true,
            dataIndex: 'timezone',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.firma_zarzadzajaca,
            flex: 2,
            sortable: true,
            dataIndex: 'firmaZarzadzajaca',
            filter: {
                type: 'string'
            }
        }, {
            header: Lang.data_wygasniecia,
            flex: 1,
            dataIndex: 'expirationdate',
            renderer: function (value, record) {
                if (value == null) {
                    return '';
                }
                return Ext.Date.format(new Date(value), Date.patterns.ISO8601Short);
            }
        }, {
            header: Lang.sim_quota,
            flex: 2,
            sortable: false,
            dataIndex: 'simquota',
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 2).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filterable: false,
            hidden: !Const.ENABLECUSTOMVENDOR
        }];
        if (!Const.ENABLECUSTOMVENDOR) {
            removeObjectFromArrayById(columnsArray, "simquota");
        }
        var recordType = [{
            name: 'id',
            mapping: 'id'
        }, {
            name: 'nazwa',
            mapping: 'nazwa'
        }, {
            name: 'opis',
            mapping: 'opis'
        }, {
            name: 'adres',
            mapping: 'adres'
        }, {
            name: 'telefon',
            mapping: 'telefon'
        }, {
            name: 'nip',
            mapping: 'nip'
        }, {
            name: 'kraj',
            mapping: 'kraj'
        }, {
            name: 'email',
            mapping: 'email'
        }, {
            name: 'typSystemu',
            mapping: 'billingInfo.typSystemu'
        }, {
            name: 'lang',
            mapping: 'lang'
        }, {
            name: 'timezone',
            mapping: 'timezone'
        }, {
            name: 'expirationdate',
            mapping: 'expirationdate'
        }, {
            name: 'simquota',
            mapping: 'simquota'
        }, "firmaZarzadzajaca"];
        let store = new Ext.data.Store({
            remoteSort: true,
            remoteFilter: true,
            autoLoad: false,
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: 'id',
                fields: recordType
            }),
            sorters: [{
                property: "nazwa",
                direction: "ASC"
            }],
            proxy: {
                type: 'ajax',
                url: 'rest/firmy/getList',
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'id'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true
            },
            store: store,
            columns: columnsArray,
            frame: false,
            border: false,
            stateId: "this.grid-" + this.id,
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagingtoolbarexd',
                displayInfo: true,
                pagingStore: store
            },
            viewConfig: {
                forceFit: true,
                getRowClass: function (record, index) {
                    if (record.data.alreadyExpired) {
                        return 'rowRed';
                    } else if (record.data.expiresWithinAMonth) {
                        return 'rowOrange';
                    } else {
                        return '';
                    }
                }
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(ViewDashboard.accessPropertyViewName)) {
                this.fetchDashboard();
            }
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            iconAlign: 'left',
            iconCls: "iconNew",
            text: Lang.nowy.capitalise(),
            scope: this,
            handler: this.dodajObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewFirmas.accessPropertyManageName)
        }, {
            id: this.id + "bedit2",
            iconAlign: 'left',
            iconCls: "iconEdit",
            text: Lang.edytuj.capitalise(),
            scope: this,
            handler: this.edytujObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewFirmas.accessPropertyManageName)
        }, {
            id: this.id + "bdelete2",
            iconAlign: 'left',
            iconCls: "iconDelete",
            text: Lang.usun.capitalise(),
            scope: this,
            handler: this.usunObiekt,
            hidden: true,
            disabled: !validPrivilegesOperatorComplex(ViewFirmas.accessPropertyManageName)
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconNew",
            text: `${Lang.otworz} ${Lang.dashboard}`,
            scope: this,
            handler: this.fetchDashboard,
            disabled: !validPrivilegesOperatorComplex(ViewDashboard.accessPropertyViewName)
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconRefresh",
            text: Lang.odswiez.capitalise(),
            scope: this,
            handler: this.refreshDatastore
        }
        ]);
        this.grid.addListener("rowcontextmenu", function (grid, record, tr, rowIndex, e) {
            this.rowContext = this.grid.getStore().getAt(rowIndex);
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.taskContextMenu.addListener("hide", function () {
            this.rowContext = null;
        }, this);
        this.grid.addListener("containercontextmenu", function (grid, e) {
            this.rowContext = null;
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
    },
    manageSelect: function () {
        var access = validPrivilegesOperatorComplex(ViewFirmas.accessPropertyManageName);
        if (this.showState) {
            var bedit = Ext.getCmp(this.id + "bedit");
            var bdelete = Ext.getCmp(this.id + "bdelete");
            var bedit2 = Ext.getCmp(this.id + "bedit2");
            var bdelete2 = Ext.getCmp(this.id + "bdelete2");
            if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
                bdelete.setDisabled(true);
                bedit.setDisabled(true);
            } else {
                var row = this.grid.getSelectionModel().getSelection()[0];
                bdelete.setDisabled(!access);
                bedit.setDisabled(!access);
            }
            if (this.rowContext == null) {
                bdelete2.setDisabled(true);
                bedit2.setDisabled(true);
            } else {
                bdelete2.setDisabled(!access);
                bedit2.setDisabled(!access);
            }
        }
    },
    refreshDatastore: function () {
        this.grid.store.load();
    },
    dodajObiekt: function () {
        windowObject = Ext.create('WindowFirma');
        windowObject.showW(0);
        windowObject.on('closeSuccess', this.refreshDatastore, this);
    },
    edytujObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        windowObject = Ext.create('WindowFirma');
        windowObject.showW(id);
        windowObject.on('closeSuccess', this.refreshDatastore, this);
    },
    usunObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            message: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/firmy/deleteObject", {
                        id: id
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    },
    fetchDashboard: function () {
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                firmaId = rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                firmaId = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        this.mask(Lang.ladowanie);
        performAjaxLPromise('rest/systemreq/setFirmaContext', {id: firmaId}).then(() => {
            let dashboard = Ext.getCmp('dashboard');
            window.location.hash = '#uvroute=dashboard';
            dashboard.fetchFD();
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        }).finally(() => {
            this.unmask();
        });
    }
});
