Ext.define('WindowFirmaExternal', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.rejestracja.capitalise(),
    width: 800,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    dashboard: false,
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            items: [{
                columnWidth: 1,
                xtype: 'form',
                itemId: 'formpanel1',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                defaults: {
                    anchor: '0',
                    labelWidth: 150,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    itemId: "nazwa",
                    name: "nazwa",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.nazwa,
                    allowBlank: false,
                    maxLength: 200,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    itemId: "adres",
                    name: "adres",
                    xtype: 'textareaexd',
                    fieldLabel: Lang.adres,
                    allowBlank: false,
                    maxLength: 150,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    itemId: "telefon",
                    name: "telefon",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.telefon,
                    allowBlank: false,
                    maxLength: 12,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    itemId: "nip",
                    name: "nip",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.nip,
                    allowBlank: false,
                    maxLength: 25,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    itemId: "kraj",
                    name: "kraj",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.kraj,
                    allowBlank: false,
                    maxLength: 56,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    itemId: "email",
                    name: "email",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.email,
                    allowBlank: false,
                    maxLength: 54,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    itemId: "lang",
                    name: "lang",
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [],
                        fields: ['value', 'text']
                    }),
                    fieldLabel: Lang.jezyk,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    displayField: 'text',
                    valueField: 'value',
                    triggerAction: 'all',
                    resizable: false,
                    mode: 'local',
                    editable: false,
                    listeners: {
                        scope: this
                    }
                }, {
                    itemId: "timezone",
                    name: "timezone",
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [],
                        fields: ['value', 'text'],
                        sorters: [{
                            property: "text",
                            direction: "ASC"
                        }]
                    }),
                    fieldLabel: Lang.strefa_czasowa,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    displayField: 'text',
                    valueField: 'value',
                    triggerAction: 'all',
                    resizable: false,
                    mode: 'local',
                    editable: false,
                    listeners: {
                        scope: this
                    }
                }, {
                    xtype: "checkboxexd",
                    id: this.cid + "processingaccepted1",
                    boxLabel: new Ext.Template(Lang.wyrazam_zgode_na_regulamin).apply({
                        cloud: "_cloud",
                        lang: resolveHtmlDocumentLang()
                    }),
                    hidden: false,
                    listeners: {
                        change: function () {
                            this.reloadAcceptFlags()
                        },
                        scope: this
                    }
                }, {
                    xtype: "checkboxexd",
                    id: this.cid + "processingaccepted2",
                    boxLabel: new Ext.Template(Lang.wyrazam_zgode_na_przetwarzanie).apply({
                        cloud: "_cloud",
                        lang: resolveHtmlDocumentLang()
                    }),
                    hidden: false,
                    listeners: {
                        change: function () {
                            this.reloadAcceptFlags()
                        },
                        scope: this
                    }
                }, {
                    xtype: "checkboxexd",
                    id: this.cid + "processingaccepted3",
                    boxLabel: Lang.wyrazam_zgode_na_przekazywanie,
                    hidden: false,
                    listeners: {
                        change: function () {
                            this.reloadAcceptFlags()
                        },
                        scope: this
                    }
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            itemId: 'save',
            text: Lang.rejestruj_firme,
            scope: this,
            handler: this.saveOp,
            disabled: true
        }, {
            itemId: 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        this.obiekt = null;
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        performAjaxLPromise('rest/systemkonf/readLangList').then(data => {
            let languages = data.map(lang => [lang.value, lang.text]);
            this.down('#lang').store.loadData(languages);
            this.down('#lang').setValue(languages.find(type => type[0] == "pl"));
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        });
        performAjaxLPromise('rest/systemkonf/readTimezoneList').then(data => {
            let timezones = data.map(tz => [tz.value, tz.text]);
            this.down('#timezone').store.loadData(timezones);
            this.down('#timezone').setValue(timezones.find(type => type[0] == "Europe/Warsaw"))
        }).catch(error => {
            handleCommonCallBackAjaxRPCError(error);
        });
    },
    saveOp: function () {
        let emailField = this.down("#email");
        const emailReg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!emailReg.test(emailField.getValue())) {
            emailField.markInvalid(Lang.niepoprawny_email);
            return;
        }
        var canWrite = this.down('#formpanel1').isValid();
        if (canWrite) {
            var nazwaFromField = this.down("#nazwa").getValue();
            this.mask(Lang.zapisywanie);
            let newFirma = {
                nazwa: nazwaFromField,
                // opis: this.down("#opis").getValue(),
                adres: this.down("#adres").getValue(),
                telefon: this.down("#telefon").getValue(),
                nip: this.down("#nip").getValue(),
                kraj: this.down('#kraj').getValue(),
                email: emailField.getValue(),
                lang: this.down("#lang").getValue(),
                timezone: this.down("#timezone").getValue()
            };
            performAjaxLPromise(`rest/firmy/createExternalObject`, newFirma).then(() => {
                this.fireEvent('closeSuccess');
                Ext.MessageBox.alert(Lang.sukces, Lang.firma_pomyslnie_zarejestrowana_haslo_wyslane);
                this.close();
            }).catch((error) => {
                this.unmask();
                handleCommonCallBackAjaxRPCError(error);
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    reloadAcceptFlags: function () {
        var accept1 = Ext.getCmp(this.cid + "processingaccepted1").getValue();
        var accept2 = Ext.getCmp(this.cid + "processingaccepted2").getValue();
        var accept3 = Ext.getCmp(this.cid + "processingaccepted3").getValue();
        this.down('#save').setDisabled(!accept1 || !accept2 || !accept3);
    },
    closeOp: function () {
        this.close();
    }
});
